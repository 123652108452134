import { useState } from "react";
import { auth } from "../firebase/config";
import {
  createUserWithEmailAndPassword,
  sendSignInLinkToEmail,
} from "firebase/auth";
import { useAuthContext } from "./useAuthContext";
import userEvent from "@testing-library/user-event";
import { FirebaseError } from "firebase/app";
import {
  collection,
  addDoc,
  serverTimestamp,
  setDoc,
  doc,
} from "firebase/firestore";
import { db } from "../firebase/config";
import { useNavigate } from "react-router-dom";

export const useSignup = () => {
  const [error, setError] = useState("");
  const { dispatch } = useAuthContext();
  const navigate = useNavigate();

  const signup = (email: string, password: string, displayName: string) => {
    setError("");
    createUserWithEmailAndPassword(auth, email, password)
      .then((res) => {
        dispatch({ type: "LOGIN", payload: res.user });
        const uid = res.user.uid;
        const data = {
          id: uid,
          email,
          displayName,
        };
        const ref = doc(db, "users", uid);
        setDoc(ref, data);
      })
      .catch((err) => {
        setError(err.message);
      });

    navigate("/team");
  };

  return { error, signup };
};
