import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../../firebase/config";
import { useAuthContext } from "../../hooks/useAuthContext";
import { Grid, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

export const ModalInfo = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [writer, setWriter] = useState("");
  const { user } = useAuthContext();

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    maxWidth: 400,
    height: 450,
    maxHeight: 800,
    bgcolor: "background.paper",
    border: "2px solid #652d90",
    boxShadow: 24,
    borderRadius: "5px",
    p: 4,
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const ref = collection(db, "Info");

    await addDoc(ref, {
      title: title,
      content: content,
      uid: user.uid,
      writer: writer,
      createdAt: serverTimestamp(),
    });
    setTitle("");
    setContent("");
  };

  return (
    <div>
      <Button
        variant="contained"
        color="secondary"
        size="large"
        type="submit"
        onClick={handleOpen}
        sx={{ width: 75, height: 55, marginLeft: "20px" }}
      >
        Add Info
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style} justifyContent="center">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            color="primary"
          >
            Enter Info
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container justifyContent="center">
              <Grid item xs={12} md={12}>
                <TextField
                  label="Writer"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={writer}
                  style={{ color: "white" }}
                  // sx={{ marginLeft: "20px" }}
                  onChange={(event) => setWriter(event.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  label="Titel"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={title}
                  style={{ color: "white" }}
                  // sx={{ marginLeft: "20px" }}
                  onChange={(event) => setTitle(event.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={12} marginTop={1}>
                <TextField
                  label=""
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={10}
                  value={content}
                  style={{ color: "white" }}
                  // sx={{ marginLeft: "20px" }}
                  onChange={(event) => setContent(event.target.value)}
                />
              </Grid>
              <Grid
                item
                xs={12}
                marginLeft={12}
                justifyContent="center"
                marginTop={1}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  type="submit"
                  sx={{ width: 75, height: 55, marginLeft: "20px" }}
                >
                  <AddIcon />
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </div>
  );
};
