import React, { FC, useEffect, useState } from "react";
import { db } from "../../firebase/config";
import {
  onSnapshot,
  collection,
  query,
  orderBy,
  addDoc,
  serverTimestamp,
} from "@firebase/firestore";
import {
  Grid,
  Typography,
  Paper,
  Container,
  TextField,
  Button,
} from "@mui/material";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import { useAuthContext } from "../../hooks/useAuthContext";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import ChildrenList from "./ChildrenList";
import { Likes } from "./Likes";

export const Info = () => {
  const [infos, setInfos] = useState([]);
  const [comment, setComment] = useState("");
  const [name, setName] = useState("");
  const { user } = useAuthContext();

  useEffect(() => {
    const ref = query(collection(db, "Info"), orderBy("createdAt", "desc"));

    const unsub = onSnapshot(ref, (snapshot) => {
      let results: any = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setInfos(results);
    });
    return () => unsub();
  }, []);

  return (
    <Container>
      {infos.map((info: any) => (
        <Paper elevation={3}>
          <Grid
            container
            key={info.id}
            id={info.id}
            marginTop={2}
            marginBottom={2}
            alignItems="center"
            // sx={{ borderTop: 2, borderColor: "#652d90", borderRadius: 1 }}
          >
            <Grid item xs={4} justifyContent="start">
              <Typography variant="h6">{info.writer}</Typography>
            </Grid>
            <Paper style={{ width: "100%", backgroundColor: "#652d90" }}>
              <Grid item md={12} xs={12}>
                <Typography
                  variant="h6"
                  className="goal"
                  style={{ color: "white" }}
                >
                  {info.title}
                </Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <Typography
                  variant="subtitle1"
                  className="goal"
                  style={{ color: "white", whiteSpace: "pre-line" }}
                >
                  {info.content}
                </Typography>
              </Grid>
            </Paper>
            <Likes path={`Info/${info.id}/likes`} />

            <ChildrenList path={`Info/${info.id}/comments`} />

            <Grid container marginTop={1}>
              <Grid item xs={12}>
                <form
                  onSubmit={(e: React.SyntheticEvent) => {
                    e.preventDefault();
                    const ref = collection(db, "Info", info.id, "comments");

                    addDoc(ref, {
                      comment: comment,
                      name: name,
                      createdAt: serverTimestamp(),
                    });
                    setComment("");
                    setName("");
                  }}
                >
                  <Grid
                    container
                    alignItems="center"
                    // sx={{ border: 2, borderColor: "#652d90", borderRadius: 1 }}
                  >
                    <Grid item xs={2}>
                      <ChatOutlinedIcon />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        label="Name"
                        color="primary"
                        variant="standard"
                        fullWidth
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      ></TextField>
                    </Grid>
                    <Grid item xs={5}>
                      <TextField
                        label="Add a comment..."
                        color="primary"
                        variant="standard"
                        fullWidth
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                      ></TextField>
                    </Grid>
                    <Grid item xs={1}>
                      <Button type="submit">Post</Button>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      ))}
    </Container>
  );
};
