import { useState } from "react";
import {
  collection,
  addDoc,
  serverTimestamp,
  doc,
  setDoc,
} from "firebase/firestore";
import { Grid, Typography, Container } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { db } from "../../../firebase/config";
import { useAuthContext } from "../../../hooks/useAuthContext";
import Paper from "@mui/material/Paper";
import AddIcon from "@mui/icons-material/Add";

const AddGoal = ({ uid }: any) => {
  const [goal, setGoal] = useState("");
  const [apparatus, setApparatus] = useState("");
  const [date, setDate] = useState("");
  const { user } = useAuthContext();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const ref = collection(db, "4WeekGoals", user.uid, "userGoals");

    await addDoc(ref, {
      goal: goal,
      apparatus: apparatus,
      date: date,
      completed: false,
      uid: user.uid,
      createdAt: serverTimestamp(),
    });
    setGoal("");
    setApparatus("");
    setDate("");
  };

  return (
    <Paper style={{ width: "80%" }}>
      <Container>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} marginTop={1} marginBottom={2}>
            <Grid item md={6} xs={12}>
              <TextField
                label="Voeg goal toe"
                color="secondary"
                variant="outlined"
                fullWidth
                value={goal}
                onChange={(event) => setGoal(event.target.value)}
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <TextField
                label="Voeg toestel toe"
                variant="outlined"
                fullWidth
                color="secondary"
                value={apparatus}
                onChange={(event) => setApparatus(event.target.value)}
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <TextField
                label="Voeg datum toe"
                variant="outlined"
                fullWidth
                color="secondary"
                value={date}
                onChange={(event) => setDate(event.target.value)}
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                type="submit"
                sx={{ width: 75, height: 55 }}
              >
                <AddIcon />
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Paper>
  );
};

export default AddGoal;
