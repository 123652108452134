import { Grid, Typography, Container } from "@mui/material";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { ModalInfo } from "./ModalInfo";
import { Info } from "./Info";

const HomePage = () => {
  return (
    <Container>
      <Grid container justifyContent="end" marginTop={1}>
        <Grid item>
          <ModalInfo />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={8} xs={12}>
          <Info />
        </Grid>
      </Grid>
    </Container>
  );
};

export default HomePage;
