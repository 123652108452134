import {
  collection,
  query,
  onSnapshot,
  where,
  orderBy,
} from "firebase/firestore";
import { useAuthContext } from "../../hooks/useAuthContext";
import Grid from "@mui/material/Unstable_Grid2";
import { Typography, Container } from "@mui/material";
import AddWedstrijd from "./ componenten/AddWedstrijd";
import AddWedstrijdMobile from "./ componenten/AddWedstrijdMobile";
import React, { useEffect, useState } from "react";
import { db } from "../../firebase/config";
import ListWedstrijden from "./ componenten/ListWedstrijden";
import ListWedstrijdenMobile from "./ componenten/ListWedstrijdenMobile";

const WedstrijdPage = () => {
  const { user } = useAuthContext();
  const [wedstrijdList, setWedstrijdList] = useState([]);

  useEffect(() => {
    const ref = query(
      collection(db, "Wedstrijden", user.uid, "userWedstrijden"),
      orderBy("createdAt", "desc")
    );

    const unsub = onSnapshot(ref, (snapshot) => {
      let results: any = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setWedstrijdList(results);
    });
    return () => unsub();
  }, []);

  return (
    <>
      <Grid container justifyContent="center" marginTop={4}>
        <Typography
          variant="h4"
          style={{ color: "#EB008B", marginTop: "20px" }}
        >
          Wedstrijd Progressie
        </Typography>
      </Grid>
      <Grid container justifyContent="center" marginTop={2}>
        <Grid
          md={12}
          justifyContent="center"
          display={{ xs: "none", md: "block" }}
          marginLeft={6}
        >
          <AddWedstrijd uid={user.uid} />
        </Grid>
        <Grid
          xs={12}
          justifyContent="center"
          display={{ md: "none", xs: "block" }}
        >
          <AddWedstrijdMobile uid={user.uid} />
        </Grid>
      </Grid>
      <Grid container justifyContent="center" marginTop={2}>
        <Grid
          md={12}
          justifyContent="center"
          display={{ xs: "none", md: "block" }}
          marginLeft={6}
        >
          {wedstrijdList && <ListWedstrijden wedstrijden={wedstrijdList} />}
        </Grid>
        <Grid
          md={12}
          justifyContent="center"
          display={{ md: "none", xs: "block" }}
        >
          {wedstrijdList && (
            <ListWedstrijdenMobile wedstrijden={wedstrijdList} />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default WedstrijdPage;
