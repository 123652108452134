import Paper from "@mui/material/Paper";
import { Grid, Typography, Container } from "@mui/material";
import Button from "@mui/material/Button";
import {
  doc,
  updateDoc,
  deleteDoc,
  collection,
  getDocs,
} from "firebase/firestore";
import Checkbox from "@mui/material/Checkbox";
import { db } from "../../../firebase/config";
import { useAuthContext } from "../../../hooks/useAuthContext";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

const ListGoal = ({ goals }: any) => {
  const { user } = useAuthContext();

  // update checkobx
  const toggleComplete = async (goal: any) => {
    await updateDoc(doc(db, "4WeekGoals", user.uid, "userGoals", goal.id), {
      completed: !goal.completed,
    });
  };

  // deleting
  const handleDelete = async (id: any) => {
    const ref = doc(db, "4WeekGoals", user.uid, "userGoals", id);
    await deleteDoc(ref);
  };

  return (
    <Container>
      {goals.map((goal: any) => (
        <Paper style={{ width: "100%" }}>
          <Grid
            container
            key={goal.id}
            marginTop={2}
            marginBottom={2}
            spacing={2}
            alignItems="center"
          >
            <Grid item md={6} xs={12}>
              <Typography variant="h6" className="goal">
                {goal.goal}
              </Typography>
            </Grid>
            <Grid item md={2} xs={4}>
              <Typography variant="h6" className="goal">
                {goal.apparatus}
              </Typography>
            </Grid>
            <Grid item md={2} xs={4}>
              <Typography variant="h6" className="goal">
                {goal.date}
              </Typography>
            </Grid>
            <Grid item md={1} xs={4}>
              <Checkbox
                checked={goal.completed}
                sx={{ "& .MuiSvgIcon-root": { fontSize: 36 } }}
                onClick={() => toggleComplete(goal)}
              />
            </Grid>
            <Grid item md={1} xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleDelete(goal.id)}
              >
                <DeleteOutlinedIcon />
              </Button>
            </Grid>
          </Grid>
        </Paper>
      ))}
    </Container>
  );
};

export default ListGoal;
