import { Typography, Container } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Paper from "@mui/material/Paper";

const SupF = () => {
  return (
    <Grid container>
      <Grid md={6}>
        <Paper elevation={2} style={{ backgroundColor: "#A3CC4C" }}>
          <Container>
            <Grid container marginTop={2}>
              <Grid marginTop={2}>
                <Typography variant="h5" style={{ color: "white" }}>
                  Sup F - Jeugd Div4 & Junior Div5 & Senior Div6
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                md={4}
                xs={12}
                marginTop={1}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  D Score
                </Typography>
              </Grid>
              <Grid
                md={8}
                xs={12}
                marginTop={1}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", display: "inline" }}
                >
                  7 hoogste waarde elementen**
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                mdOffset={4}
                md={8}
                xs={12}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", display: "inline" }}
                >
                  TA (0.1) - A (0.1) - B (0.2)*
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                mdOffset={4}
                md={8}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  display={{ xs: "none", md: "block" }}
                >
                  Min 2 acro en min 3 dans op balk en vloer
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                md={4}
                marginTop={1}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  display={{ xs: "none", md: "block" }}
                >
                  Samenstellingseisen
                </Typography>
              </Grid>
              <Grid
                md={8}
                marginTop={1}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  display={{ xs: "none", md: "block" }}
                >
                  max 2.0
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                md={4}
                marginTop={1}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  display={{ xs: "none", md: "block" }}
                >
                  Verbindingswaarde
                </Typography>
              </Grid>
              <Grid
                md={8}
                marginTop={1}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  display={{ xs: "none", md: "block" }}
                >
                  0.10 of 0.20 per keer
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                md={4}
                marginTop={1}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  display={{ xs: "none", md: "block" }}
                >
                  E-score
                </Typography>
              </Grid>
              <Grid
                md={8}
                marginTop={1}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  display={{ xs: "none", md: "block" }}
                >
                  max 10.00
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                md={4}
                marginTop={3}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography variant="h6" style={{ color: "white" }}>
                  Samenstellingseisen
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid md={6} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h6" style={{ color: "white" }}>
                  Sprong - Meerkamp
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid md={12} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  1. Er moet 1 sprong uitgevoerd worden.
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid md={12} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h6" style={{ color: "white" }}>
                  Sprong - ToestelF en Kwalificatie
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid md={12} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  1. 2 verschillende sprongen
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid md={12} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  2. Het eindcijfer is het gemiddelde
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                md={12}
                marginTop={2}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography variant="h6" style={{ color: "white" }}>
                  Brug Ongelijk
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid md={11} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  1. 1 kip
                </Typography>
              </Grid>
              <Grid md={1} style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  display={{ xs: "none", md: "block" }}
                >
                  0.50
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid md={11} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  2. Gesloten opzwaai min horizontaal
                </Typography>
              </Grid>
              <Grid md={1} style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  display={{ xs: "none", md: "block" }}
                >
                  0.50
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid md={11} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  3. Een cirkelelement uit 2,3,4, of 5 min A
                </Typography>
              </Grid>
              <Grid md={1} style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  display={{ xs: "none", md: "block" }}
                >
                  0.50
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid md={11} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  4. Een liggerwissel vanuit een element
                </Typography>
              </Grid>
              <Grid md={1} style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  display={{ xs: "none", md: "block" }}
                >
                  0.50
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                md={12}
                marginTop={2}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography variant="h6" style={{ color: "white" }}>
                  Balk - SE 1 t/m 4 op balk
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid md={11} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  1. Verbinding 2 dans, 1 TA-sprong beensp
                </Typography>
              </Grid>
              <Grid md={1} style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  display={{ xs: "none", md: "block" }}
                >
                  0.50
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid md={11} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  2. Een draai uit groep 3
                </Typography>
              </Grid>
              <Grid md={1} style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  display={{ xs: "none", md: "block" }}
                >
                  0.50
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid md={11} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  3. Acro element van min A waarde
                </Typography>
              </Grid>
              <Grid md={1} style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  display={{ xs: "none", md: "block" }}
                >
                  0.50
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid md={11} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  4. Acro element door of tot handstand
                </Typography>
              </Grid>
              <Grid md={1} style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  display={{ xs: "none", md: "block" }}
                >
                  0.50
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                md={12}
                marginTop={2}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography variant="h6" style={{ color: "white" }}>
                  Vloer - SE 2 t/m 4 in acrolijn
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid md={11} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  1. Dansp van 2 sprongen, ind verbonden
                </Typography>
              </Grid>
              <Grid md={1} style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  display={{ xs: "none", md: "block" }}
                >
                  0.50
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid md={11} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  2. Salto vw of aw
                </Typography>
              </Grid>
              <Grid md={1} style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  display={{ xs: "none", md: "block" }}
                >
                  0.50
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid md={11} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  3. Acrolijn met 2 vers vlucht elementen
                </Typography>
              </Grid>
              <Grid md={1} style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  display={{ xs: "none", md: "block" }}
                >
                  0.50
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                md={11}
                style={{ display: "flex", alignItems: "center" }}
                marginBottom={2}
              >
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  4. Acro handenst vw/zw en aw, 1 vlucht
                </Typography>
              </Grid>
              <Grid
                md={1}
                style={{ display: "flex", alignItems: "center" }}
                marginBottom={2}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  display={{ xs: "none", md: "block" }}
                >
                  0.50
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Paper>
      </Grid>
      <Grid md={6} display={{ xs: "none", md: "block" }}>
        <Paper elevation={2} style={{ backgroundColor: "#A3CC4C" }}>
          <Container>
            <Grid container marginTop={2}>
              <Grid
                md={12}
                marginTop={2}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography variant="h6" style={{ color: "white" }}>
                  Verbindingswaarde
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                md={9}
                marginTop={2}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography variant="h6" style={{ color: "white" }}>
                  Brug Ongelijk
                </Typography>
              </Grid>
              <Grid
                md={3}
                marginTop={2}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography variant="h6" style={{ color: "white" }}>
                  0.10
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid md={9} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  Dezelfde of verschillende elementen
                </Typography>
              </Grid>
              <Grid md={3} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  TA + A
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                md={9}
                marginTop={4}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography variant="h6" style={{ color: "white" }}>
                  Balk
                </Typography>
              </Grid>
              <Grid
                md={3}
                marginTop={4}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography variant="h6" style={{ color: "white" }}>
                  0.10
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid md={9} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  Acro met of zonder vlucht
                </Typography>
              </Grid>
              <Grid md={3} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  TA + A
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid md={9} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  Dans - verschillend
                </Typography>
              </Grid>
              <Grid md={3} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  TA + A
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid md={9} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  Mix
                </Typography>
              </Grid>
              <Grid md={3} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  TA + A
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                md={9}
                marginTop={4}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography variant="h6" style={{ color: "white" }}>
                  Vloer
                </Typography>
              </Grid>
              <Grid
                md={3}
                marginTop={4}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography variant="h6" style={{ color: "white" }}>
                  0.10
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid md={9} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  Acro - 2 met vlucht
                </Typography>
              </Grid>
              <Grid md={3} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  A + A+
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid md={9} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  Dans - max 2 verschillend
                </Typography>
              </Grid>
              <Grid md={3} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  TA + A+
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid md={9} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  Mix - in volgorde
                </Typography>
              </Grid>
              <Grid md={3} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  TA-acro +
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                md={3}
                mdOffset={9}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  A-dans*
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                md={3}
                mdOffset={9}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  A-acro* +
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                md={3}
                mdOffset={9}
                marginBottom={55}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  TA-dans* +
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default SupF;
