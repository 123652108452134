import { useState } from "react";
import {
  collection,
  addDoc,
  serverTimestamp,
  doc,
  setDoc,
} from "firebase/firestore";
import { Grid, Typography, Container, Paper } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { db } from "../../../firebase/config";
import { useAuthContext } from "../../../hooks/useAuthContext";
import AddIcon from "@mui/icons-material/Add";

export const AddDscore = () => {
  const [date, setDate] = useState("");
  const [apparatus, setApparatus] = useState("");
  const [routine, setRoutine] = useState("");
  const [mw, setMw] = useState("");
  const [se, setSe] = useState("");
  const [vw, setVw] = useState("");
  const [dScore, setDScore] = useState("");
  const [acroTA, setAcroTA] = useState("");
  const [acroA, setAcroA] = useState("");
  const [acroB, setAcroB] = useState("");
  const [acroC, setAcroC] = useState("");
  const [acroD, setAcroD] = useState("");
  const [acroE, setAcroE] = useState("");
  const [acroF, setAcroF] = useState("");
  const [dansTA, setDansTA] = useState("");
  const [dansA, setDansA] = useState("");
  const [dansB, setDansB] = useState("");
  const [dansC, setDansC] = useState("");
  const [dansD, setDansD] = useState("");
  const [dansE, setDansE] = useState("");
  const [dansF, setDansF] = useState("");
  const [overTA, setOverTA] = useState("");
  const [overA, setOverA] = useState("");
  const [overB, setOverB] = useState("");
  const [overC, setOverC] = useState("");
  const [overD, setOverD] = useState("");
  const [overE, setOverE] = useState("");
  const [overF, setOverF] = useState("");
  const [nAftrek, setNAftrek] = useState("");
  const { user } = useAuthContext();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const ref = collection(db, "DScore", user.uid, "userDScore");

    await addDoc(ref, {
      date: date,
      apparatus: apparatus,
      routine: routine,
      s1: false,
      s2: false,
      s3: false,
      s4: false,
      mw: mw,
      se: se,
      vw: vw,
      dScore: dScore,
      acroTA: acroTA,
      acroA: acroA,
      acroB: acroB,
      acroC: acroC,
      acroD: acroD,
      acroE: acroE,
      acroF: acroF,
      dansTA: dansTA,
      dansA: dansA,
      dansB: dansB,
      dansC: dansC,
      dansD: dansD,
      dansE: dansE,
      dansF: dansF,
      overTA: overTA,
      overA: overA,
      overB: overB,
      overC: overC,
      overD: overD,
      overE: overE,
      overF: overF,
      nAftrek: nAftrek,
      uid: user.uid,
      createdAt: serverTimestamp(),
    });
    setDate("");
    setApparatus("");
    setRoutine("");
    setSe("");
    setMw("");
    setVw("");
    setDScore("");
    setAcroTA("");
    setAcroA("");
    setAcroB("");
    setAcroC("");
    setAcroD("");
    setAcroE("");
    setAcroF("");
    setDansTA("");
    setDansA("");
    setDansB("");
    setDansC("");
    setDansD("");
    setDansE("");
    setDansF("");
    setOverTA("");
    setOverA("");
    setOverB("");
    setOverC("");
    setOverD("");
    setOverE("");
    setOverF("");
    setNAftrek("");
  };

  return (
    <>
      <Paper style={{ width: "90%" }}>
        <Container>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item md={6}>
                <TextField
                  label="Datum"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={date}
                  onChange={(event) => setDate(event.target.value)}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  label="Toestel"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={apparatus}
                  onChange={(event) => setApparatus(event.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent="center">
              <Grid item md={12}>
                <TextField
                  label="Oefening"
                  color="secondary"
                  variant="outlined"
                  multiline
                  rows={3}
                  fullWidth
                  value={routine}
                  onChange={(event) => setRoutine(event.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent="center">
              <Grid item md={1}>
                <Paper style={{ backgroundColor: "#652d90", height: 30 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    SE
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper style={{ backgroundColor: "#652d90", height: 30 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    1
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper style={{ backgroundColor: "#652d90", height: 30 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    2
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper style={{ backgroundColor: "#652d90", height: 30 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    3
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper style={{ backgroundColor: "#652d90", height: 30 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    4
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper style={{ backgroundColor: "#652d90", height: 30 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    MW
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper style={{ backgroundColor: "#652d90", height: 30 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    TA
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper style={{ backgroundColor: "#652d90", height: 30 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    A
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper style={{ backgroundColor: "#652d90", height: 30 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    B
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper style={{ backgroundColor: "#652d90", height: 30 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    C
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper style={{ backgroundColor: "#652d90", height: 30 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    D
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <Paper style={{ backgroundColor: "#652d90", height: 30 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    E
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid container spacing={2} marginTop={1} justifyContent="center">
              <Grid item md={1}>
                <Paper style={{ backgroundColor: "#652d90", height: 55 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    MW
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <TextField
                  label="MW"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={mw}
                  onChange={(event) => setMw(event.target.value)}
                />
              </Grid>
              <Grid item md={1}>
                <Paper style={{ backgroundColor: "#652d90", height: 55 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    SE
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <TextField
                  label="SE"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={se}
                  onChange={(event) => setSe(event.target.value)}
                />
              </Grid>
              <Grid item md={1}></Grid>
              <Grid item md={1}>
                <Paper style={{ backgroundColor: "#652d90", height: 55 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    Acro
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <TextField
                  label="TA"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={acroTA}
                  onChange={(event) => setAcroTA(event.target.value)}
                />
              </Grid>
              <Grid item md={1}>
                <TextField
                  label="A"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={acroA}
                  onChange={(event) => setAcroA(event.target.value)}
                />
              </Grid>
              <Grid item md={1}>
                <TextField
                  label="B"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={acroB}
                  onChange={(event) => setAcroB(event.target.value)}
                />
              </Grid>
              <Grid item md={1}>
                <TextField
                  label="C"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={acroC}
                  onChange={(event) => setAcroC(event.target.value)}
                />
              </Grid>
              <Grid item md={1}>
                <TextField
                  label="D"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={acroD}
                  onChange={(event) => setAcroD(event.target.value)}
                />
              </Grid>
              <Grid item md={1}>
                <TextField
                  label="E"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={acroE}
                  onChange={(event) => setAcroE(event.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} marginTop={1} justifyContent="center">
              <Grid item md={1}>
                <Paper style={{ backgroundColor: "#652d90", height: 55 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    VW
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <TextField
                  label="VW"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={vw}
                  onChange={(event) => setVw(event.target.value)}
                />
              </Grid>
              <Grid item md={1}>
                <Paper style={{ backgroundColor: "#652d90", height: 55 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    D score
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <TextField
                  label="D Score"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={dScore}
                  onChange={(event) => setDScore(event.target.value)}
                />
              </Grid>
              <Grid item md={1}></Grid>
              <Grid item md={1}>
                <Paper style={{ backgroundColor: "#652d90", height: 55 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    Dans
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <TextField
                  label="TA"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={dansTA}
                  onChange={(event) => setDansTA(event.target.value)}
                />
              </Grid>
              <Grid item md={1}>
                <TextField
                  label="A"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={dansA}
                  onChange={(event) => setDansA(event.target.value)}
                />
              </Grid>
              <Grid item md={1}>
                <TextField
                  label="B"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={dansB}
                  onChange={(event) => setDansB(event.target.value)}
                />
              </Grid>
              <Grid item md={1}>
                <TextField
                  label="C"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={dansC}
                  onChange={(event) => setDansC(event.target.value)}
                />
              </Grid>
              <Grid item md={1}>
                <TextField
                  label="D"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={dansD}
                  onChange={(event) => setDansD(event.target.value)}
                />
              </Grid>
              <Grid item md={1}>
                <TextField
                  label="E"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={dansE}
                  onChange={(event) => setDansE(event.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} marginTop={1} justifyContent="center">
              <Grid item md={1}>
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  type="submit"
                  sx={{ width: 75, height: 55 }}
                >
                  <AddIcon />
                </Button>
              </Grid>
              <Grid item md={1}></Grid>
              <Grid item md={1}>
                <Paper style={{ backgroundColor: "#652d90", height: 55 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    N aftrek
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <TextField
                  label="N aftrek"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={nAftrek}
                  onChange={(event) => setNAftrek(event.target.value)}
                />
              </Grid>
              <Grid item md={1}></Grid>
              <Grid item md={1}>
                <Paper style={{ backgroundColor: "#652d90", height: 55 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    Over
                  </Typography>
                </Paper>
              </Grid>
              <Grid item md={1}>
                <TextField
                  label="TA"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={overTA}
                  onChange={(event) => setOverTA(event.target.value)}
                />
              </Grid>
              <Grid item md={1}>
                <TextField
                  label="A"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={overA}
                  onChange={(event) => setOverA(event.target.value)}
                />
              </Grid>
              <Grid item md={1}>
                <TextField
                  label="B"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={overB}
                  onChange={(event) => setOverB(event.target.value)}
                />
              </Grid>
              <Grid item md={1}>
                <TextField
                  label="C"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={overC}
                  onChange={(event) => setOverC(event.target.value)}
                />
              </Grid>
              <Grid item md={1}>
                <TextField
                  label="D"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={overD}
                  onChange={(event) => setOverD(event.target.value)}
                />
              </Grid>
              <Grid item md={1}>
                <TextField
                  label="E"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={overE}
                  onChange={(event) => setOverE(event.target.value)}
                />
              </Grid>
            </Grid>
          </form>
        </Container>
      </Paper>
    </>
  );
};
