import Paper from "@mui/material/Paper";
import { Typography, Container } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

const ListWedstrijden = ({ wedstrijden }: any) => {
  return (
    <>
      {wedstrijden.map((wedstrijd: any) => (
        <Paper style={{ width: "95%", backgroundColor: "#652d90" }}>
          <Container key={wedstrijd.id}>
            <Grid container marginTop={1}>
              <Grid md={6} marginTop={2}>
                <Paper style={{ backgroundColor: "#00bdd9", height: 55 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    {wedstrijd.wedstrijd}
                  </Typography>
                </Paper>
              </Grid>
              <Grid md={6} marginTop={2}>
                <Paper style={{ backgroundColor: "#00bdd9", height: 55 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    {wedstrijd.datum}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid container marginTop={1}>
              <Grid md={1} mdOffset={2}>
                <Paper style={{ backgroundColor: "#A3CC4C", height: 55 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    Sprong 1
                  </Typography>
                </Paper>
              </Grid>
              <Grid md={1}>
                <Paper style={{ backgroundColor: "#A3CC4C", height: 55 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    Sprong 2
                  </Typography>
                </Paper>
              </Grid>
              <Grid md={2}>
                <Paper style={{ backgroundColor: "#A3CC4C", height: 55 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    Brug
                  </Typography>
                </Paper>
              </Grid>
              <Grid md={2}>
                <Paper style={{ backgroundColor: "#A3CC4C", height: 55 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    Balk
                  </Typography>
                </Paper>
              </Grid>
              <Grid md={2}>
                <Paper style={{ backgroundColor: "#A3CC4C", height: 55 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    Vloer
                  </Typography>
                </Paper>
              </Grid>
              <Grid md={2}>
                <Paper style={{ backgroundColor: "#A3CC4C", height: 55 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    AA
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid container marginTop={1}>
              <Grid md={2}>
                <Paper style={{ backgroundColor: "#A3CC4C", height: 55 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    D-score
                  </Typography>
                </Paper>
              </Grid>
              <Grid md={1}>
                <Paper style={{ backgroundColor: "#00bdd9", height: 55 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    {wedstrijd.dSprong1}
                  </Typography>
                </Paper>
              </Grid>
              <Grid md={1}>
                <Paper style={{ backgroundColor: "#00bdd9", height: 55 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    {wedstrijden.dSprong2}
                  </Typography>
                </Paper>
              </Grid>
              <Grid md={2}>
                <Paper style={{ backgroundColor: "#00bdd9", height: 55 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    {wedstrijd.dBrug}
                  </Typography>
                </Paper>
              </Grid>
              <Grid md={2}>
                <Paper style={{ backgroundColor: "#00bdd9", height: 55 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    {wedstrijd.dBalk}
                  </Typography>
                </Paper>
              </Grid>
              <Grid md={2}>
                <Paper style={{ backgroundColor: "#00bdd9", height: 55 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    {wedstrijd.dVloer}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid container marginTop={1}>
              <Grid md={2}>
                <Paper style={{ backgroundColor: "#A3CC4C", height: 55 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    E-score
                  </Typography>
                </Paper>
              </Grid>
              <Grid md={1}>
                <Paper style={{ backgroundColor: "#00bdd9", height: 55 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    {wedstrijd.eSprong1}
                  </Typography>
                </Paper>
              </Grid>
              <Grid md={1}>
                <Paper style={{ backgroundColor: "#00bdd9", height: 55 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    {wedstrijden.eSprong2}
                  </Typography>
                </Paper>
              </Grid>
              <Grid md={2}>
                <Paper style={{ backgroundColor: "#00bdd9", height: 55 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    {wedstrijd.eBrug}
                  </Typography>
                </Paper>
              </Grid>
              <Grid md={2}>
                <Paper style={{ backgroundColor: "#00bdd9", height: 55 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    {wedstrijd.eBalk}
                  </Typography>
                </Paper>
              </Grid>
              <Grid md={2}>
                <Paper style={{ backgroundColor: "#00bdd9", height: 55 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    {wedstrijd.eVloer}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid container marginTop={1}>
              <Grid md={2}>
                <Paper style={{ backgroundColor: "#A3CC4C", height: 55 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    Totaal
                  </Typography>
                </Paper>
              </Grid>
              <Grid md={1}>
                <Paper style={{ backgroundColor: "#00bdd9", height: 55 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    {wedstrijd.tSprong1}
                  </Typography>
                </Paper>
              </Grid>
              <Grid md={1}>
                <Paper style={{ backgroundColor: "#00bdd9", height: 55 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    {wedstrijden.tSprong2}
                  </Typography>
                </Paper>
              </Grid>
              <Grid md={2}>
                <Paper style={{ backgroundColor: "#00bdd9", height: 55 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    {wedstrijd.tBrug}
                  </Typography>
                </Paper>
              </Grid>
              <Grid md={2}>
                <Paper style={{ backgroundColor: "#00bdd9", height: 55 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    {wedstrijd.tBalk}
                  </Typography>
                </Paper>
              </Grid>
              <Grid md={2}>
                <Paper style={{ backgroundColor: "#00bdd9", height: 55 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    {wedstrijd.tVloer}
                  </Typography>
                </Paper>
              </Grid>
              <Grid md={2}>
                <Paper style={{ backgroundColor: "#00bdd9", height: 55 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    {wedstrijd.tTotaal}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid container marginTop={1}>
              <Grid md={2}>
                <Paper style={{ backgroundColor: "#A3CC4C", height: 55 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    Plaats
                  </Typography>
                </Paper>
              </Grid>
              <Grid md={2}>
                <Paper style={{ backgroundColor: "#00bdd9", height: 55 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    {wedstrijd.pSprong}
                  </Typography>
                </Paper>
              </Grid>
              <Grid md={2}>
                <Paper style={{ backgroundColor: "#00bdd9", height: 55 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    {wedstrijd.pBrug}
                  </Typography>
                </Paper>
              </Grid>
              <Grid md={2}>
                <Paper style={{ backgroundColor: "#00bdd9", height: 55 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    {wedstrijd.pBalk}
                  </Typography>
                </Paper>
              </Grid>
              <Grid md={2}>
                <Paper style={{ backgroundColor: "#00bdd9", height: 55 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    {wedstrijd.pVloer}
                  </Typography>
                </Paper>
              </Grid>
              <Grid md={2}>
                <Paper style={{ backgroundColor: "#00bdd9", height: 55 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    {wedstrijd.pTotaal}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid container marginTop={1}>
              <Grid md={2} marginBottom={4}>
                <Paper style={{ backgroundColor: "#A3CC4C", height: 55 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    Evaluatie
                  </Typography>
                </Paper>
              </Grid>
              <Grid md={10} marginBottom={4}>
                <Paper style={{ backgroundColor: "#00bdd9", height: 55 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    {wedstrijd.notes}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Paper>
      ))}
    </>
  );
};

export default ListWedstrijden;
