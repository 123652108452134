import React, { useEffect, useState } from "react";
import { Typography, Container, Grid } from "@mui/material";
import { AddDscore } from "./components/AddDscore";
import { useAuthContext } from "../../hooks/useAuthContext";
import { db } from "../../firebase/config";
import {
  collection,
  query,
  onSnapshot,
  where,
  orderBy,
} from "firebase/firestore";
import { ListDScore } from "./components/ListDScore";
import { AddDScoreMobile } from "./components/AddDScoreMobile";
import { ListDScoreMobile } from "./components/ListDScoreMobile";

export const DScore = () => {
  const { user } = useAuthContext();
  const [dScoreList, setDScoreList] = useState([]);

  useEffect(() => {
    const ref = query(
      collection(db, "DScore", user.uid, "userDScore"),
      orderBy("createdAt", "desc")
    );

    const unsub = onSnapshot(ref, (snapshot) => {
      let results: any = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setDScoreList(results);
    });
    return () => unsub();
  }, []);

  return (
    <>
      <Grid container justifyContent="center" marginTop={4} marginBottom={4}>
        <Typography
          variant="h4"
          style={{ color: "#EB008B", marginTop: "20px" }}
        >
          D-scores Oefeningen
        </Typography>
      </Grid>
      <Grid
        container
        margin={2}
        marginLeft={10}
        justifyContent="center"
        display={{ xs: "none", md: "block" }}
      >
        <AddDscore />
      </Grid>
      <Grid
        container
        margin={2}
        justifyContent="center"
        display={{ xs: "block", md: "none" }}
      >
        <AddDScoreMobile />
      </Grid>
      <Grid
        container
        marginTop={2}
        margin={2}
        justifyContent="center"
        display={{ xs: "none", md: "block" }}
      >
        <Grid item marginLeft={12}>
          {dScoreList && <ListDScore dScores={dScoreList} />}
        </Grid>
      </Grid>
      <Grid
        container
        margin={2}
        justifyContent="center"
        display={{ xs: "block", md: "none" }}
      >
        {dScoreList && <ListDScoreMobile dScores={dScoreList} />}
      </Grid>
    </>
  );
};
