import React, { useEffect, useState } from "react";
import { db } from "../firebase/config";
import {
  onSnapshot,
  collection,
  query,
  orderBy,
  addDoc,
  where,
  serverTimestamp,
} from "@firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";
import {
  Grid,
  Typography,
  Paper,
  Container,
  TextField,
  Button,
} from "@mui/material";
import { useAuthContext } from "../hooks/useAuthContext";

export const UserList = ({ path }: any) => {
  const { user } = useAuthContext();
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const ref = query(collection(db, path), where("id", "==", user.uid));

    const unsub = onSnapshot(ref, (snapshot) => {
      let results: any = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setUsers(results);
    });
    return () => unsub();
  }, []);

  return (
    <>
      {users?.map((user: any) => (
        <Grid
          marginLeft="auto"
          marginRight={2}
          key={user.id}
          display={{ xs: "none", md: "block" }}
        >
          <Typography variant="h6">Hello, {user.username}</Typography>
        </Grid>
      ))}
    </>
  );
};
