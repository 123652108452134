import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBqJBc8c4-kcjgidkNOtHWOwWZSpjDWNKE",
  authDomain: "personal-gymnastics.firebaseapp.com",
  projectId: "personal-gymnastics",
  storageBucket: "personal-gymnastics.appspot.com",
  messagingSenderId: "587925851081",
  appId: "1:587925851081:web:bb3af2a5f1788fde123618",
};

// init firebase
initializeApp(firebaseConfig);

// init firestore
const db = getFirestore();

// init firebase auth
const auth = getAuth();

export { db, auth };
