import { Grid, Typography, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { db } from "../../../firebase/config";

const AddSkill = ({ uid }: any) => {
  const [skill, setSkill] = useState<string>("");
  const [w1, setW1] = useState<string>("");
  const [w2, setW2] = useState<string>("");
  const [w3, setW3] = useState<string>("");
  const [w4, setW4] = useState<string>("");
  const [w5, setW5] = useState<string>("");
  const [w6, setW6] = useState<string>("");
  const [w7, setW7] = useState<string>("");
  const [w8, setW8] = useState<string>("");
  const { user } = useAuthContext();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const ref = collection(db, "Skills", user.uid, "userSkills");

    await addDoc(ref, {
      skill: skill,
      w1: w1,
      w2: w2,
      w3: w3,
      w4: w4,
      w5: w5,
      w6: w6,
      w7: w7,
      w8: w8,
      uid: user.uid,
      createdAt: serverTimestamp(),
    });
    setSkill("");
    setW1("");
    setW2("");
    setW3("");
    setW4("");
    setW5("");
    setW6("");
    setW7("");
    setW8("");
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Paper style={{ width: "95%" }}>
          <Container>
            <Grid container spacing={1} marginTop={1} marginBottom={4}>
              <Grid item md={3} xs={12} marginBottom={2}>
                <TextField
                  label="Voeg skill toe"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={skill}
                  onChange={(event) => setSkill(event.target.value)}
                />
              </Grid>
              <Grid item md={1} xs={3} marginBottom={2}>
                <TextField
                  label="W1"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={w1}
                  sx={{ marginLeft: "5px" }}
                  onChange={(event) => setW1(event.target.value)}
                />
              </Grid>
              <Grid item md={1} xs={3} marginBottom={2}>
                <TextField
                  label="W2"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={w2}
                  sx={{ marginLeft: "5px" }}
                  onChange={(event) => setW2(event.target.value)}
                />
              </Grid>
              <Grid item md={1} xs={3} marginBottom={2}>
                <TextField
                  label="W3"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={w3}
                  sx={{ marginLeft: "5px" }}
                  onChange={(event) => setW3(event.target.value)}
                />
              </Grid>
              <Grid item md={1} xs={3} marginBottom={2}>
                <TextField
                  label="W4"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={w4}
                  sx={{ marginLeft: "5px" }}
                  onChange={(event) => setW4(event.target.value)}
                />
              </Grid>
              <Grid item md={1} xs={3} marginBottom={2}>
                <TextField
                  label="W5"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={w5}
                  sx={{ marginLeft: "5px" }}
                  onChange={(event) => setW5(event.target.value)}
                />
              </Grid>
              <Grid item md={1} xs={3} marginBottom={2}>
                <TextField
                  label="W6"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={w6}
                  sx={{ marginLeft: "5px" }}
                  onChange={(event) => setW6(event.target.value)}
                />
              </Grid>
              <Grid item md={1} xs={3} marginBottom={2}>
                <TextField
                  label="W7"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={w7}
                  sx={{ marginLeft: "5px" }}
                  onChange={(event) => setW7(event.target.value)}
                />
              </Grid>
              <Grid item md={1} xs={3} marginBottom={2}>
                <TextField
                  label="W8"
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  value={w8}
                  sx={{ marginLeft: "5px" }}
                  onChange={(event) => setW8(event.target.value)}
                />
              </Grid>
              <Grid item md={1} xs={12}>
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  type="submit"
                  sx={{ width: 75, height: 55, marginLeft: "20px" }}
                >
                  <AddIcon />
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Paper>
      </form>
    </Box>
  );
};

export default AddSkill;
