import { Grid, Typography, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import AddSkill from "./components/AddSkill";
import { useAuthContext } from "../../hooks/useAuthContext";
import SkillList from "./components/SkillList";
import {
  collection,
  query,
  onSnapshot,
  where,
  orderBy,
} from "firebase/firestore";
import { db } from "../../firebase/config";
import { SkillListMobile } from "./components/SkillListMobile";
import AddSkillMobile from "./components/AddSkillMobile";

const SkillTrackerPage = () => {
  const { user } = useAuthContext();
  const [skillList, setSkillList] = useState([]);

  useEffect(() => {
    const ref = query(
      collection(db, "Skills", user.uid, "userSkills"),
      orderBy("createdAt", "desc")
    );

    const unsub = onSnapshot(ref, (snapshot) => {
      let results: any = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setSkillList(results);
    });
    return () => unsub();
  }, []);

  return (
    <div>
      <Grid container justifyContent="Center" marginTop={4}>
        <Typography
          variant="h4"
          style={{ color: "#EB008B", marginTop: "20px" }}
        >
          Skill Tracker
        </Typography>
      </Grid>
      <Grid container justifyContent="Center" marginTop={4}>
        <Paper style={{ width: "90%", backgroundColor: "#00bdd9" }}>
          <Typography
            variant="h6"
            style={{ marginTop: "10px", marginBottom: "10px", color: "white" }}
          >
            Gebruik de tabel om je progressie te monitoren. Schrijf de skills op
            waar je mee bezig bent en vul per week je score in.
          </Typography>
        </Paper>
      </Grid>
      <Grid container justifyContent="Center" marginTop={4}>
        <Paper
          style={{
            width: "18%",
            backgroundColor: "#A3CC4C",
            marginLeft: "1px",
            marginRight: "1px",
          }}
        >
          <Typography
            variant="subtitle2"
            style={{ marginTop: "10px", marginBottom: "10px", color: "white" }}
          >
            1: Met Hulp
          </Typography>
        </Paper>
        <Paper
          style={{
            width: "18%",
            backgroundColor: "#A3CC4C",
            marginLeft: "1px",
            marginRight: "1px",
          }}
        >
          <Typography
            variant="subtitle2"
            style={{ marginTop: "10px", marginBottom: "10px", color: "white" }}
          >
            2: Zonder Hulp
          </Typography>
        </Paper>
        <Paper
          style={{
            width: "18%",
            backgroundColor: "#A3CC4C",
            marginLeft: "1px",
            marginRight: "1px",
          }}
        >
          <Typography
            variant="subtitle2"
            style={{ marginTop: "10px", marginBottom: "10px", color: "white" }}
          >
            3: 1x Gelukt
          </Typography>
        </Paper>
        <Paper
          style={{
            width: "18%",
            backgroundColor: "#A3CC4C",
            marginLeft: "1px",
            marginRight: "1px",
          }}
        >
          <Typography
            variant="subtitle2"
            style={{ marginTop: "10px", marginBottom: "10px", color: "white" }}
          >
            4: Technisch Goed Gelukt
          </Typography>
        </Paper>
        <Paper
          style={{
            width: "18%",
            backgroundColor: "#A3CC4C",
            marginLeft: "1px",
            marginRight: "1px",
          }}
        >
          <Typography
            variant="subtitle2"
            style={{ marginTop: "10px", marginBottom: "10px", color: "white" }}
          >
            5: 3x Achter Elkaar
          </Typography>
        </Paper>
      </Grid>
      <Grid
        container
        justifyContent="center"
        marginTop={4}
        display={{ xs: "none", md: "block" }}
      >
        <AddSkill uid={user.uid} />
      </Grid>
      <Grid
        container
        justifyContent="center"
        marginTop={4}
        display={{ xs: "block", md: "none" }}
      >
        <AddSkillMobile uid={user.uid} />
      </Grid>
      <Grid
        container
        justifyContent="center"
        marginTop={4}
        display={{ xs: "none", md: "block" }}
      >
        {skillList && <SkillList skills={skillList} />}
      </Grid>
      <Grid
        container
        justifyContent="center"
        marginTop={4}
        display={{ xs: "block", md: "none" }}
      >
        {skillList && <SkillListMobile skills={skillList} />}
      </Grid>
    </div>
  );
};

export default SkillTrackerPage;
