import Paper from "@mui/material/Paper";
import React, { useEffect, useState } from "react";
import { Grid, Typography, Container } from "@mui/material";
import Button from "@mui/material/Button";
import { doc, deleteDoc, collection, getDocs } from "firebase/firestore";
import { db } from "../../../firebase/config";
import { useAuthContext } from "../../../hooks/useAuthContext";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { ModalSkill } from "./ModalSkill";
import AddIcon from "@mui/icons-material/Add";

const SkillList = ({ skills }: any) => {
  const { user } = useAuthContext();
  console.log(user);

  // deleting
  const handleDelete = async (id: any) => {
    const ref = doc(db, "Skills", user.uid, "userSkills", id);
    await deleteDoc(ref);
  };

  return (
    <Container>
      {skills.map((skill: any) => (
        <Paper style={{ width: "98%" }}>
          <Grid
            container
            key={skill.id}
            marginTop={2}
            marginBottom={2}
            spacing={2}
            alignItems="center"
          >
            <Grid item md={3}>
              <Paper style={{ backgroundColor: "#652d90", height: 35 }}>
                <Typography variant="h6" style={{ color: "white" }}>
                  Skill
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <Paper style={{ backgroundColor: "#652d90", height: 35 }}>
                <Typography variant="h6" style={{ color: "white" }}>
                  W1
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <Paper style={{ backgroundColor: "#652d90", height: 35 }}>
                <Typography variant="h6" style={{ color: "white" }}>
                  W2
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <Paper style={{ backgroundColor: "#652d90", height: 35 }}>
                <Typography variant="h6" style={{ color: "white" }}>
                  W3
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <Paper style={{ backgroundColor: "#652d90", height: 35 }}>
                <Typography variant="h6" style={{ color: "white" }}>
                  W4
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <Paper style={{ backgroundColor: "#652d90", height: 35 }}>
                <Typography variant="h6" style={{ color: "white" }}>
                  W5
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <Paper style={{ backgroundColor: "#652d90", height: 35 }}>
                <Typography variant="h6" style={{ color: "white" }}>
                  W6
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <Paper style={{ backgroundColor: "#652d90", height: 35 }}>
                <Typography variant="h6" style={{ color: "white" }}>
                  W7
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <Paper style={{ backgroundColor: "#652d90", height: 35 }}>
                <Typography variant="h6" style={{ color: "white" }}>
                  W8
                </Typography>
              </Paper>
            </Grid>
            <Grid item md={1}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleDelete(skill.id)}
              >
                <DeleteOutlinedIcon />
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center" paddingBottom={1}>
            <Grid item md={3} xs={12}>
              <Typography variant="h6" className="goal">
                {skill.skill}
              </Typography>
            </Grid>
            <Grid item md={1}>
              <Typography variant="h6" className="goal">
                {skill.w1}
              </Typography>
            </Grid>
            <Grid item md={1}>
              <Typography variant="h6" className="goal">
                {skill.w2}
              </Typography>
            </Grid>
            <Grid item md={1}>
              <Typography variant="h6" className="goal">
                {skill.w3}
              </Typography>
            </Grid>
            <Grid item md={1}>
              <Typography variant="h6" className="goal">
                {skill.w4}
              </Typography>
            </Grid>
            <Grid item md={1}>
              <Typography variant="h6" className="goal">
                {skill.w5}
              </Typography>
            </Grid>
            <Grid item md={1}>
              <Typography variant="h6" className="goal">
                {skill.w6}
              </Typography>
            </Grid>
            <Grid item md={1}>
              <Typography variant="h6" className="goal">
                {skill.w7}
              </Typography>
            </Grid>
            <Grid item md={1}>
              <Typography variant="h6" className="goal">
                {skill.w8}
              </Typography>
            </Grid>
            <Grid item md={1}>
              <ModalSkill path={`Skills/${user.uid}/userSkills/${skill.id}`} />
            </Grid>
          </Grid>
        </Paper>
      ))}
    </Container>
  );
};

export default SkillList;
