import React, { useEffect, useState } from "react";
import "./App.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Routes, Route, Navigate } from "react-router-dom";
import Navbar from "./components/Header";
import HomePage from "./pages/homePage/HomePage";
import Signup from "./pages/auth/Signup";
import Login from "./pages/auth/Login";
import GoalsPage from "./pages/goalsPage/GoalsPage";
import TeamPage from "./pages/teamPage/TeamPage";
import SkillTrackerPage from "./pages/skillTrackerPage/SkillTrackerPage";
import RegelementenPage from "./pages/regelementen/RegelementenPage";
import WedstrijdPage from "./pages/wedstrijdPage/WedstrijdPage";
import { DScore } from "./pages/prepPage/DScore";
import { useAuthContext } from "./hooks/useAuthContext";

const theme = createTheme({
  palette: {
    primary: {
      main: "#652d90",
    },
    secondary: {
      main: "#eb008b",
    },
    info: {
      main: "#00bdd9",
    },
    warning: {
      main: "#FFCB03",
    },
    success: {
      main: "#A3CC4C",
    },
    error: {
      main: "#ffffff",
    },
  },
});

function App() {
  const { user } = useAuthContext();
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    if (user) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Navbar />
        <Routes>
          <Route
            path="/"
            element={!loggedIn ? <Navigate to="/login" /> : <HomePage />}
          />
          <Route
            path="/signup"
            element={loggedIn ? <Navigate to="/" /> : <Signup />}
          />
          <Route
            path="/login"
            element={loggedIn ? <Navigate to="/" /> : <Login />}
          />

          <Route path="/goals" element={<GoalsPage />} />
          <Route path="/team" element={<TeamPage />} />
          <Route path="/skills" element={<SkillTrackerPage />} />
          <Route path="/regelementen" element={<RegelementenPage />} />
          <Route path="/wedstrijden" element={<WedstrijdPage />} />
          <Route path="/dscore" element={<DScore />} />
        </Routes>
      </div>
    </ThemeProvider>
  );
}

export default App;
