import { Typography, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { db } from "../../../firebase/config";

const AddWedstrijdMobile = ({ uid }: any) => {
  const [wedstrijd, setWedstrijd] = useState("");
  const [datum, setDatum] = useState("");
  const [eBrug, setEBrug] = useState("");
  const [eSprong1, setESprong1] = useState("");
  const [eSprong2, setESprong2] = useState("");
  const [eBalk, setEBalk] = useState("");
  const [eVloer, setEVloer] = useState("");
  const [dSprong1, setDSprong1] = useState("");
  const [dSprong2, setDSprong2] = useState("");
  const [dBrug, setDBrug] = useState("");
  const [dBalk, setDBalk] = useState("");
  const [dVloer, setDVloer] = useState("");
  const [tSprong1, setTSprong1] = useState("");
  const [tSprong2, setTSprong2] = useState("");
  const [tBrug, setTBrug] = useState("");
  const [tBalk, setTBalk] = useState("");
  const [tVloer, setTVloer] = useState("");
  const [tTotaal, setTTotaal] = useState("");
  const [pSprong, setPSprong] = useState("");
  const [pBrug, setPBrug] = useState("");
  const [pBalk, setPBalk] = useState("");
  const [pVloer, setPVloer] = useState("");
  const [pTotaal, setPTotaal] = useState("");
  const [notes, setNotes] = useState("");
  const { user } = useAuthContext();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const ref = collection(db, "Wedstrijden", user.uid, "userWedstrijden");

    await addDoc(ref, {
      wedstrijd: wedstrijd,
      datum: datum,
      eSprong1: eSprong1,
      eSprong2: eSprong2,
      eBrug: eBrug,
      eBalk: eBalk,
      eVloer: eVloer,
      dSprong1: dSprong1,
      dSprong2: dSprong2,
      dBrug: dBrug,
      dBalk: dBalk,
      dVloer: dVloer,
      tSprong1: tSprong1,
      tSprong2: tSprong2,
      tBrug: tBrug,
      tBalk: tBalk,
      tVloer: tVloer,
      tTotaal: tTotaal,
      pSprong: pSprong,
      pBrug: pBrug,
      pBalk: pBalk,
      pVloer: pVloer,
      pTotaal: pTotaal,
      notes: notes,
      uid: user.uid,
      createdAt: serverTimestamp(),
    });
    setWedstrijd("");
    setDatum("");
    setESprong1("");
    setESprong2("");
    setEBrug("");
    setEBalk("");
    setEVloer("");
    setDSprong1("");
    setDSprong2("");
    setDBrug("");
    setDBalk("");
    setDVloer("");
    setTSprong1("");
    setTSprong2("");
    setTBrug("");
    setTBalk("");
    setTVloer("");
    setTTotaal("");
    setPSprong("");
    setPBrug("");
    setPBalk("");
    setPVloer("");
    setPTotaal("");
    setNotes("");
  };

  return (
    <form onSubmit={handleSubmit}>
      <Paper style={{ width: "100%", backgroundColor: "#00bdd9" }}>
        <Grid container marginTop={1} justifyContent="center">
          <Grid xs={12} marginTop={1} style={{ marginLeft: -40 }}>
            <TextField
              label="Voeg wedstrijd toe"
              color="secondary"
              variant="outlined"
              fullWidth
              value={wedstrijd}
              style={{ color: "white" }}
              sx={{ marginLeft: "20px" }}
              onChange={(event) => setWedstrijd(event.target.value)}
            />
          </Grid>
          <Grid xs={12} marginTop={1} style={{ marginLeft: -40 }}>
            <TextField
              label="Voeg datum toe"
              color="secondary"
              variant="outlined"
              fullWidth
              value={datum}
              sx={{ marginLeft: "20px" }}
              onChange={(event) => setDatum(event.target.value)}
            />
          </Grid>
        </Grid>

        <Grid container marginTop={1}>
          <Grid xs={4} style={{ marginLeft: -20 }}>
            <TextField
              label="D S 1"
              color="secondary"
              variant="outlined"
              fullWidth
              value={dSprong1}
              sx={{ marginLeft: "20px" }}
              onChange={(event) => setDSprong1(event.target.value)}
            />
          </Grid>
          <Grid xs={4} md={1}>
            <TextField
              label="ES1"
              color="secondary"
              variant="outlined"
              fullWidth
              value={eSprong1}
              sx={{ marginLeft: "20px" }}
              onChange={(event) => setESprong1(event.target.value)}
            />
          </Grid>
          <Grid xs={4} md={2}>
            <TextField
              label="TS1"
              color="secondary"
              variant="outlined"
              fullWidth
              value={tSprong1}
              sx={{ marginLeft: "20px" }}
              onChange={(event) => setTSprong1(event.target.value)}
            />
          </Grid>
        </Grid>

        <Grid container marginTop={1}>
          <Grid xs={3} style={{ marginLeft: -20 }}>
            <TextField
              label="DS2"
              color="secondary"
              variant="outlined"
              fullWidth
              value={dSprong2}
              sx={{ marginLeft: "20px" }}
              onChange={(event) => setDSprong2(event.target.value)}
            />
          </Grid>
          <Grid xs={3}>
            <TextField
              label="ES2"
              color="secondary"
              variant="outlined"
              fullWidth
              value={eSprong2}
              sx={{ marginLeft: "20px" }}
              onChange={(event) => setESprong2(event.target.value)}
            />
          </Grid>
          <Grid xs={3}>
            <TextField
              label="TS2"
              color="secondary"
              variant="outlined"
              fullWidth
              value={tSprong2}
              sx={{ marginLeft: "20px" }}
              onChange={(event) => setTSprong2(event.target.value)}
            />
          </Grid>
          <Grid xs={3}>
            <TextField
              label="P Sprong"
              color="secondary"
              variant="outlined"
              fullWidth
              value={pSprong}
              sx={{ marginLeft: "20px" }}
              onChange={(event) => setPSprong(event.target.value)}
            />
          </Grid>
        </Grid>

        <Grid container marginTop={1}>
          <Grid xs={3} style={{ marginLeft: -20 }}>
            <TextField
              label="D Brug"
              color="secondary"
              variant="outlined"
              fullWidth
              value={dBrug}
              sx={{ marginLeft: "20px" }}
              onChange={(event) => setDBrug(event.target.value)}
            />
          </Grid>
          <Grid xs={3}>
            <TextField
              label="E Brug"
              color="secondary"
              variant="outlined"
              fullWidth
              value={eBrug}
              sx={{ marginLeft: "20px" }}
              onChange={(event) => setEBrug(event.target.value)}
            />
          </Grid>
          <Grid xs={3}>
            <TextField
              label="T Brug"
              color="secondary"
              variant="outlined"
              fullWidth
              value={tBrug}
              sx={{ marginLeft: "20px" }}
              onChange={(event) => setTBrug(event.target.value)}
            />
          </Grid>
          <Grid xs={3}>
            <TextField
              label="P Brug"
              color="secondary"
              variant="outlined"
              fullWidth
              value={pBrug}
              sx={{ marginLeft: "20px" }}
              onChange={(event) => setPBrug(event.target.value)}
            />
          </Grid>
        </Grid>

        <Grid container marginTop={1}>
          <Grid xs={3} style={{ marginLeft: -20 }}>
            <TextField
              label="D Balk"
              color="secondary"
              variant="outlined"
              fullWidth
              value={dBalk}
              sx={{ marginLeft: "20px" }}
              onChange={(event) => setDBalk(event.target.value)}
            />
          </Grid>
          <Grid xs={3}>
            <TextField
              label="E Balk"
              color="secondary"
              variant="outlined"
              fullWidth
              value={eBalk}
              sx={{ marginLeft: "20px" }}
              onChange={(event) => setEBalk(event.target.value)}
            />
          </Grid>
          <Grid xs={3}>
            <TextField
              label="T Balk"
              color="secondary"
              variant="outlined"
              fullWidth
              value={tBalk}
              sx={{ marginLeft: "20px" }}
              onChange={(event) => setTBalk(event.target.value)}
            />
          </Grid>
          <Grid xs={3}>
            <TextField
              label="P Balk"
              color="secondary"
              variant="outlined"
              fullWidth
              value={pBalk}
              sx={{ marginLeft: "20px" }}
              onChange={(event) => setPBalk(event.target.value)}
            />
          </Grid>
        </Grid>

        <Grid container marginTop={1}>
          <Grid xs={3} style={{ marginLeft: -20 }}>
            <TextField
              label="D Vloer"
              color="secondary"
              variant="outlined"
              fullWidth
              value={dVloer}
              sx={{ marginLeft: "20px" }}
              onChange={(event) => setDVloer(event.target.value)}
            />
          </Grid>
          <Grid xs={3}>
            <TextField
              label="E Vloer"
              color="secondary"
              variant="outlined"
              fullWidth
              value={eVloer}
              sx={{ marginLeft: "20px" }}
              onChange={(event) => setEVloer(event.target.value)}
            />
          </Grid>
          <Grid xs={3}>
            <TextField
              label="T Vloer"
              color="secondary"
              variant="outlined"
              fullWidth
              value={tVloer}
              sx={{ marginLeft: "20px" }}
              onChange={(event) => setTVloer(event.target.value)}
            />
          </Grid>
          <Grid xs={3}>
            <TextField
              label="P Vloer"
              color="secondary"
              variant="outlined"
              fullWidth
              value={pVloer}
              sx={{ marginLeft: "20px" }}
              onChange={(event) => setPVloer(event.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container marginTop={1}>
          <Grid xs={12} style={{ marginLeft: -20 }}>
            <TextField
              label="Evaluatie"
              color="secondary"
              variant="outlined"
              fullWidth
              value={notes}
              sx={{ marginLeft: "20px" }}
              onChange={(event) => setNotes(event.target.value)}
            />
          </Grid>
        </Grid>

        <Grid container marginTop={1}>
          <Grid xs={4} style={{ marginLeft: -20 }} marginBottom={1}>
            <TextField
              label="T AA"
              color="secondary"
              variant="outlined"
              fullWidth
              value={tTotaal}
              sx={{ marginLeft: "20px" }}
              onChange={(event) => setTTotaal(event.target.value)}
            />
          </Grid>
          <Grid xs={4}>
            <TextField
              label="P AA"
              color="secondary"
              variant="outlined"
              fullWidth
              value={pTotaal}
              sx={{ marginLeft: "20px" }}
              onChange={(event) => setPTotaal(event.target.value)}
            />
          </Grid>
          <Grid xs={4}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              type="submit"
              sx={{ width: 75, height: 55, marginLeft: "20px" }}
            >
              <AddIcon />
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </form>
  );
};

export default AddWedstrijdMobile;
