import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Grid, Typography, Container } from "@mui/material";

const TeamInfo = () => {
  return (
    <Paper elevation={2} style={{ backgroundColor: "#00bdd9" }}>
      <Container>
        <Grid container marginBottom={2}>
          <Grid item md={12} marginTop={4}>
            <Typography variant="h5" style={{ color: "white" }}>
              Lestijden:
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Typography variant="h6" style={{ color: "white" }}>
              Maandag (extra):{" "}
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Typography variant="h6" style={{ color: "white" }}>
              18:30 - 21:00
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Typography variant="h6" style={{ color: "white" }}>
              Dinsdag:{" "}
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Typography variant="h6" style={{ color: "white" }}>
              18:30 - 21:00
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Typography variant="h6" style={{ color: "white" }}>
              Vrijdag:{" "}
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Typography variant="h6" style={{ color: "white" }}>
              19:00 - 21:30
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Typography variant="h6" style={{ color: "white" }}>
              Zaterdag (extra):{" "}
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Typography variant="h6" style={{ color: "white" }}>
              09:30 - 12:30
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Paper>
  );
};

export default TeamInfo;
