import React, { useState } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Grid, Typography, Container } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useSignup } from "../../hooks/useSignup";

const Signup = () => {
  const [username, setUsername] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const { error, signup } = useSignup();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    signup(email, password, username);
    setUsername("");
    setEmail("");
    setPassword("");
  };

  return (
    <Container>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} md={6} marginTop={12}>
          <Paper elevation={2}>
            <form onSubmit={handleSubmit}>
              <Container>
                <Grid container maxWidth="lg" display="flex">
                  <Grid item xs={12}>
                    <Typography>Signup</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Name"
                      fullWidth
                      type="text"
                      variant="standard"
                      onChange={(event) => setUsername(event.target.value)}
                      value={username}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Email"
                      fullWidth
                      type="email"
                      variant="standard"
                      onChange={(event) => setEmail(event.target.value)}
                      value={email}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Password"
                      autoComplete="new-password"
                      fullWidth
                      type="password"
                      variant="standard"
                      onChange={(event) => setPassword(event.target.value)}
                      value={password}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} marginTop={4} marginBottom={4}>
                    <Button type="submit" variant="contained" size="large">
                      Signup
                    </Button>
                  </Grid>
                </Grid>
              </Container>
              {error && <p>{error}</p>}
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Signup;
