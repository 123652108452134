import { useState } from "react";
import { auth } from "../firebase/config";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useAuthContext } from "./useAuthContext";

export const useLogin = () => {
  const [error, setError] = useState("");
  const { dispatch } = useAuthContext();

  const login = (email: string, password: string) => {
    setError("");
    signInWithEmailAndPassword(auth, email, password)
      .then((res) => {
        dispatch({ type: "LOGIN", payload: res.user });
        console.log("user logged in:", res.user);
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  return { error, login };
};
