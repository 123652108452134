import { Typography, Container } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Paper from "@mui/material/Paper";

const SupE = () => {
  return (
    <Grid container>
      <Grid md={6}>
        <Paper elevation={2} style={{ backgroundColor: "#00bdd9" }}>
          <Container>
            <Grid container marginTop={2}>
              <Grid marginTop={2}>
                <Typography variant="h5" style={{ color: "white" }}>
                  Sup E - Jeugd Div3 & Junior Div4 & Senior Div5
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                md={4}
                xs={12}
                marginTop={1}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  D Score
                </Typography>
              </Grid>
              <Grid
                md={8}
                xs={12}
                marginTop={1}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", display: "inline" }}
                >
                  7 hoogste waarde elementen**
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                mdOffset={4}
                md={8}
                xs={12}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white", display: "inline" }}
                >
                  TA (0.1) - A (0.1) - B (0.2)*
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                mdOffset={4}
                md={8}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  display={{ xs: "none", md: "block" }}
                >
                  Min 2 acro en min 3 dans op balk en vloer
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                md={4}
                marginTop={1}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  display={{ xs: "none", md: "block" }}
                >
                  Samenstellingseisen
                </Typography>
              </Grid>
              <Grid
                md={8}
                marginTop={1}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  display={{ xs: "none", md: "block" }}
                >
                  max 2.0
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                md={4}
                marginTop={1}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  display={{ xs: "none", md: "block" }}
                >
                  Verbindingswaarde
                </Typography>
              </Grid>
              <Grid
                md={8}
                marginTop={1}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  display={{ xs: "none", md: "block" }}
                >
                  0.10 of 0.20 per keer
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                md={4}
                marginTop={1}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  display={{ xs: "none", md: "block" }}
                >
                  E-score
                </Typography>
              </Grid>
              <Grid
                md={8}
                marginTop={1}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  display={{ xs: "none", md: "block" }}
                >
                  max 10.00
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                md={4}
                marginTop={3}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography variant="h6" style={{ color: "white" }}>
                  Samenstellingseisen
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid md={6} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h6" style={{ color: "white" }}>
                  Sprong - Meerkamp
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid md={12} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  1. Er moet 1 sprong uitgevoerd worden.
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid md={12} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h6" style={{ color: "white" }}>
                  Sprong - ToestelF en Kwalificatie
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid md={12} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  1. 2 verschillende sprongen
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid md={12} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  2. Het eindcijfer is het gemiddelde
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                md={12}
                marginTop={2}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography variant="h6" style={{ color: "white" }}>
                  Brug Ongelijk
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid md={11} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  1. 1 kip
                </Typography>
              </Grid>
              <Grid md={1} style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  display={{ xs: "none", md: "block" }}
                >
                  0.50
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid md={11} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  2. Gesloten opzwaai min horizontaal
                </Typography>
              </Grid>
              <Grid md={1} style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  display={{ xs: "none", md: "block" }}
                >
                  0.50
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid md={11} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  3. 2 versch elementen uit groep 2,3,4, of 5
                </Typography>
              </Grid>
              <Grid md={1} style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  display={{ xs: "none", md: "block" }}
                >
                  0.50
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid md={11} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  4. Element met 180º in oef/opspr of 2e kip
                </Typography>
              </Grid>
              <Grid md={1} style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  display={{ xs: "none", md: "block" }}
                >
                  0.50
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                md={12}
                marginTop={2}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography variant="h6" style={{ color: "white" }}>
                  Balk - SE 1 t/m 4 op balk
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid md={11} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  1. Verbinding 2 dans, 1 A-sprong beenspr
                </Typography>
              </Grid>
              <Grid md={1} style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  display={{ xs: "none", md: "block" }}
                >
                  0.50
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid md={11} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  2. Een draai uit groep 3 of rol/flair
                </Typography>
              </Grid>
              <Grid md={1} style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  display={{ xs: "none", md: "block" }}
                >
                  0.50
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid md={11} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  3. Acro element van min A waarde
                </Typography>
              </Grid>
              <Grid md={1} style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  display={{ xs: "none", md: "block" }}
                >
                  0.50
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid md={11} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  4. 2 vers acro, 1 door/tot handstand
                </Typography>
              </Grid>
              <Grid md={1} style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  display={{ xs: "none", md: "block" }}
                >
                  0.50
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                md={12}
                marginTop={2}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography variant="h6" style={{ color: "white" }}>
                  Vloer - SE 2 t/m 4 in acrolijn
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid md={11} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  1. Dansp van 2 sprongen, ind verbonden
                </Typography>
              </Grid>
              <Grid md={1} style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  display={{ xs: "none", md: "block" }}
                >
                  0.50
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid md={11} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  2. Salto vw of aw
                </Typography>
              </Grid>
              <Grid md={1} style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  display={{ xs: "none", md: "block" }}
                >
                  0.50
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid md={11} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  3. Acrolijn 2 vers elementen met vlucht
                </Typography>
              </Grid>
              <Grid md={1} style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  display={{ xs: "none", md: "block" }}
                >
                  0.50
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                md={11}
                style={{ display: "flex", alignItems: "center" }}
                marginBottom={2}
              >
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  4. Acro vlucht en handens vw/zw en aw
                </Typography>
              </Grid>
              <Grid
                md={1}
                style={{ display: "flex", alignItems: "center" }}
                marginBottom={2}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "white" }}
                  display={{ xs: "none", md: "block" }}
                >
                  0.50
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Paper>
      </Grid>
      <Grid md={6} display={{ xs: "none", md: "block" }}>
        <Paper elevation={2} style={{ backgroundColor: "#00bdd9" }}>
          <Container>
            <Grid container marginTop={2}>
              <Grid
                md={12}
                marginTop={2}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography variant="h6" style={{ color: "white" }}>
                  Verbindingswaarde
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                md={8}
                marginTop={2}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography variant="h6" style={{ color: "white" }}>
                  Brug Ongelijk
                </Typography>
              </Grid>
              <Grid
                md={2}
                marginTop={2}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography variant="h6" style={{ color: "white" }}>
                  0.10
                </Typography>
              </Grid>
              <Grid
                md={2}
                marginTop={2}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography variant="h6" style={{ color: "white" }}>
                  0.20
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid md={8} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  Dezelfde of verschillende elementen
                </Typography>
              </Grid>
              <Grid md={2} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  A + B
                </Typography>
              </Grid>
              <Grid md={2} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  A + B*
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                md={8}
                marginTop={4}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography variant="h6" style={{ color: "white" }}>
                  Balk
                </Typography>
              </Grid>
              <Grid
                md={2}
                marginTop={4}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography variant="h6" style={{ color: "white" }}>
                  0.10
                </Typography>
              </Grid>
              <Grid
                md={2}
                marginTop={4}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography variant="h6" style={{ color: "white" }}>
                  0.20
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid md={8} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  Acro met of zonder vlucht
                </Typography>
              </Grid>
              <Grid md={2} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  A + A
                </Typography>
              </Grid>
              <Grid md={2} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  A + B*
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid md={8} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  Dans - verschillend
                </Typography>
              </Grid>
              <Grid md={2} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  A + A
                </Typography>
              </Grid>
              <Grid md={2} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  A + B*
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid md={8} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  Mix
                </Typography>
              </Grid>
              <Grid md={2} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  A + A
                </Typography>
              </Grid>
              <Grid md={2} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  A + B*
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                md={8}
                marginTop={4}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography variant="h6" style={{ color: "white" }}>
                  Vloer
                </Typography>
              </Grid>
              <Grid
                md={2}
                marginTop={4}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography variant="h6" style={{ color: "white" }}>
                  0.10
                </Typography>
              </Grid>
              <Grid
                md={2}
                marginTop={4}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography variant="h6" style={{ color: "white" }}>
                  0.20
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid md={8} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  Acro - 2 met vlucht 1 salto
                </Typography>
              </Grid>
              <Grid md={2} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  A + A
                </Typography>
              </Grid>
              <Grid md={2} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  A + B*
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid md={8} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  Dans - max 2 verschillend
                </Typography>
              </Grid>
              <Grid md={2} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  A + A
                </Typography>
              </Grid>
              <Grid md={2} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  A + B*
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid md={8} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  Mix - in volgorde
                </Typography>
              </Grid>
              <Grid md={4} style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  A-acro +
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                md={4}
                mdOffset={8}
                marginBottom={62}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  A-dans*
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default SupE;
