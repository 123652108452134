import React, { useEffect, useState } from "react";
import { db } from "../../firebase/config";
import {
  onSnapshot,
  collection,
  query,
  orderBy,
  addDoc,
  serverTimestamp,
  deleteDoc,
  doc,
} from "@firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";
import {
  Grid,
  Typography,
  Paper,
  Container,
  TextField,
  Button,
} from "@mui/material";
import Moment from "react-moment";

export default function ChildrenList({ path }: any) {
  const query = collection(db, path);
  const [docs, loading, error] = useCollectionData(query);

  return (
    <>
      {docs?.map((doc: any) => (
        <Grid container key={doc.id} alignItems="center">
          <Grid item xs={1}></Grid>
          <Grid item xs={3} md={2}>
            <Typography fontWeight="bold" variant="subtitle1">
              {doc.name}
            </Typography>
          </Grid>
          <Grid item xs={8} md={7}>
            <Typography textAlign="left" variant="subtitle1">
              {doc.comment}
            </Typography>
          </Grid>
          <Grid item md={2} display={{ xs: "none", md: "block" }}>
            <Moment className="Moment" fromNow>
              {doc.createdAt?.toDate()}
            </Moment>
          </Grid>
        </Grid>
      ))}
    </>
  );
}
