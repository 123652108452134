import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Typography, Container } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

const Wedstrijd = () => {
  return (
    <Paper elevation={2} style={{ backgroundColor: "#A3CC4C" }}>
      <Container>
        <Grid container marginBottom={2}>
          <Grid md={12} marginTop={4}>
            <Typography variant="h5" style={{ color: "white" }}>
              Wedstrijd Kalender 2022-2023:
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid md={12}>
            <Typography variant="h6" style={{ color: "white" }}>
              Mogelijke opties voor Senior 3, Junior 2 & Senior 2
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} marginTop={1}>
          <Grid md={8} style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6" style={{ color: "white" }}>
              Za 5 & zo 6 november 2022
            </Typography>
          </Grid>
          <Grid md={4} style={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="h6"
              style={{ color: "white" }}
              display={{ xs: "none", md: "block" }}
            >
              Dalen
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid md={8} style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6" style={{ color: "white" }}>
              Za 12 & zo 13 november 2022
            </Typography>
          </Grid>
          <Grid md={4} style={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="h6"
              style={{ color: "white" }}
              display={{ xs: "none", md: "block" }}
            >
              Zierikzee
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid md={8} style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6" style={{ color: "white" }}>
              Za 26 & zo 27 november 2022
            </Typography>
          </Grid>
          <Grid md={4} style={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="h6"
              style={{ color: "white" }}
              display={{ xs: "none", md: "block" }}
            >
              Winterswijk
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid md={8} style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6" style={{ color: "white" }}>
              Za 10 & zo 11 december 2022
            </Typography>
          </Grid>
          <Grid md={4} style={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="h6"
              style={{ color: "white" }}
              display={{ xs: "none", md: "block" }}
            >
              Waalwijk
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid md={8} style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6" style={{ color: "white" }}>
              Za 14 & zo 15 januari 2023
            </Typography>
          </Grid>
          <Grid md={4} style={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="h6"
              style={{ color: "white" }}
              display={{ xs: "none", md: "block" }}
            >
              Vianen
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid md={8} style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6" style={{ color: "white" }}>
              Za 21 & zo 22 januari 2023
            </Typography>
          </Grid>
          <Grid md={4} style={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="h6"
              style={{ color: "white" }}
              display={{ xs: "none", md: "block" }}
            >
              Weert
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid md={8} style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6" style={{ color: "white" }}>
              Za 4 & zo 4 februari 2023
            </Typography>
          </Grid>
          <Grid md={4} style={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="h6"
              style={{ color: "white" }}
              display={{ xs: "none", md: "block" }}
            >
              Duiven
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid md={8} style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6" style={{ color: "white" }}>
              Za 11 & zo 12 februari 2023
            </Typography>
          </Grid>
          <Grid md={4} style={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="h6"
              style={{ color: "white" }}
              display={{ xs: "none", md: "block" }}
            >
              Reusel
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid md={8} style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6" style={{ color: "white" }}>
              Za 11 & zo 12 maart 2023
            </Typography>
          </Grid>
          <Grid md={4} style={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="h6"
              style={{ color: "white" }}
              display={{ xs: "none", md: "block" }}
            >
              Volendam
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid md={8} style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6" style={{ color: "white" }}>
              Za 18 & zo 19 maart 2023
            </Typography>
          </Grid>
          <Grid md={4} style={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="h6"
              style={{ color: "white" }}
              display={{ xs: "none", md: "block" }}
            >
              Rotterdam
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid md={8} style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6" style={{ color: "white" }}>
              Za 1 & zo 2 april 2023
            </Typography>
          </Grid>
          <Grid md={4} style={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="h6"
              style={{ color: "white" }}
              display={{ xs: "none", md: "block" }}
            >
              Den Haag
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid md={8} style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6" style={{ color: "white" }}>
              Za 15 & zo 16 april 2023
            </Typography>
          </Grid>
          <Grid md={4} style={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="h6"
              style={{ color: "white" }}
              display={{ xs: "none", md: "block" }}
            >
              Nijmegen
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid md={8} style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6" style={{ color: "white" }}>
              Za 22 & zo 23 april 2023
            </Typography>
          </Grid>
          <Grid md={4} style={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="h6"
              style={{ color: "white" }}
              display={{ xs: "none", md: "block" }}
            >
              Alkmaar
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Paper>
  );
};

export default Wedstrijd;
