import Paper from "@mui/material/Paper";
import { Grid, Typography, Container } from "@mui/material";
import Button from "@mui/material/Button";
import { doc, deleteDoc, collection, getDocs } from "firebase/firestore";
import { db } from "../../../firebase/config";
import { useAuthContext } from "../../../hooks/useAuthContext";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { ModalSkillMobile } from "./ModalSkillMobile";

export const SkillListMobile = ({ skills }: any) => {
  const { user } = useAuthContext();
  console.log(user);

  const handleDelete = async (id: any) => {
    const ref = doc(db, "Skills", user.uid, "userSkills", id);
    await deleteDoc(ref);
  };

  return (
    <Container>
      {skills.map((skill: any) => (
        <Paper style={{ width: "100%" }}>
          <Grid container key={skill.id} spacing={2} alignItems="center">
            <Grid item xs={12}>
              <Paper style={{ backgroundColor: "#652d90", height: 30 }}>
                <Typography variant="h6" style={{ color: "white" }}>
                  Skill
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} marginBottom={1}>
              <Typography variant="h6" className="goal">
                {skill.skill}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            key={skill.id}
            marginBottom={1}
            spacing={2}
            alignItems="center"
          >
            <Grid item xs={3}>
              <Paper style={{ backgroundColor: "#652d90", height: 30 }}>
                <Typography variant="h6" style={{ color: "white" }}>
                  W1
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper style={{ backgroundColor: "#652d90", height: 30 }}>
                <Typography variant="h6" style={{ color: "white" }}>
                  W2
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper style={{ backgroundColor: "#652d90", height: 30 }}>
                <Typography variant="h6" style={{ color: "white" }}>
                  W3
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper style={{ backgroundColor: "#652d90", height: 30 }}>
                <Typography variant="h6" style={{ color: "white" }}>
                  W4
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h6" className="goal">
                {skill.w1}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h6" className="goal">
                {skill.w2}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h6" className="goal">
                {skill.w3}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h6" className="goal">
                {skill.w4}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            key={skill.id}
            marginBottom={1}
            spacing={2}
            alignItems="center"
          >
            <Grid item xs={3}>
              <Paper style={{ backgroundColor: "#652d90", height: 30 }}>
                <Typography variant="h6" style={{ color: "white" }}>
                  W5
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper style={{ backgroundColor: "#652d90", height: 30 }}>
                <Typography variant="h6" style={{ color: "white" }}>
                  W6
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper style={{ backgroundColor: "#652d90", height: 30 }}>
                <Typography variant="h6" style={{ color: "white" }}>
                  W7
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper style={{ backgroundColor: "#652d90", height: 30 }}>
                <Typography variant="h6" style={{ color: "white" }}>
                  W8
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h6" className="goal">
                {skill.w5}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h6" className="goal">
                {skill.w6}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h6" className="goal">
                {skill.w7}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h6" className="goal">
                {skill.w8}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            key={skill.id}
            marginBottom={4}
            paddingBottom={1}
            spacing={2}
            alignItems="center"
          >
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleDelete(skill.id)}
              >
                <DeleteOutlinedIcon />
              </Button>
            </Grid>
            <Grid item xs={6}>
              <ModalSkillMobile
                path={`Skills/${user.uid}/userSkills/${skill.id}`}
              />
            </Grid>
          </Grid>
        </Paper>
      ))}
    </Container>
  );
};
