import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Typography, Container } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

const WedstrijdRayon = () => {
  return (
    <Paper elevation={2} style={{ backgroundColor: "#eb008b" }}>
      <Container>
        <Grid container marginTop={2}>
          <Grid md={12} marginTop={2}>
            <Typography variant="h5" style={{ color: "white" }}>
              Wedstrijd Kalender 2022-2023:
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid md={12}>
            <Typography variant="h6" style={{ color: "white" }}>
              Mogelijke opties voor Divisie 4/5/6
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid md={9} style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6" style={{ color: "white" }}>
              Za 03 december 2022
            </Typography>
          </Grid>
          <Grid md={3} style={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="h6"
              style={{ color: "white" }}
              display={{ xs: "none", md: "block" }}
            >
              Voorwedstrijd
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid md={9} style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6" style={{ color: "white" }}>
              Zaterdag 21 januari 2023
            </Typography>
          </Grid>
          <Grid md={3} style={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="h6"
              style={{ color: "white" }}
              display={{ xs: "none", md: "block" }}
            >
              Voorwedstrijd
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid md={9} style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6" style={{ color: "white" }}>
              Zaterdag 04 februari 2023
            </Typography>
          </Grid>
          <Grid md={3} style={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="h6"
              style={{ color: "white" }}
              display={{ xs: "none", md: "block" }}
            >
              Voorwedstrijd
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid md={9} style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6" style={{ color: "white" }}>
              Zaterdag 18 februari 2023
            </Typography>
          </Grid>
          <Grid md={3} style={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="h6"
              style={{ color: "white" }}
              display={{ xs: "none", md: "block" }}
            >
              Voorwedstrijd
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid md={9} style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6" style={{ color: "white" }}>
              Zaterdag 11 maart 2023
            </Typography>
          </Grid>
          <Grid md={3} style={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="h6"
              style={{ color: "white" }}
              display={{ xs: "none", md: "block" }}
            >
              Voorwedstrijd
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid md={9} style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6" style={{ color: "white" }}>
              Zaterdag 25 maart 2023
            </Typography>
          </Grid>
          <Grid md={3} style={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="h6"
              style={{ color: "white" }}
              display={{ xs: "none", md: "block" }}
            >
              Voorwedstrijd
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid md={9} style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6" style={{ color: "white" }}>
              Zaterdag 08 april 2023
            </Typography>
          </Grid>
          <Grid md={3} style={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="h6"
              style={{ color: "white" }}
              display={{ xs: "none", md: "block" }}
            >
              Teamwedstrijd
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid md={9} style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6" style={{ color: "white" }}>
              Zaterdag 15 april 2023
            </Typography>
          </Grid>
          <Grid md={3} style={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="h6"
              style={{ color: "white" }}
              display={{ xs: "none", md: "block" }}
            >
              Wedstrijd
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid md={9} style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6" style={{ color: "white" }}>
              Zaterdag 10 juni 2023
            </Typography>
          </Grid>
          <Grid md={3} style={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="h6"
              style={{ color: "white" }}
              display={{ xs: "none", md: "block" }}
            >
              RK
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid
            md={9}
            style={{ display: "flex", alignItems: "center" }}
            marginBottom={1}
          >
            <Typography variant="h6" style={{ color: "white" }}>
              Zaterdag 24 juni 2023
            </Typography>
          </Grid>
          <Grid
            md={3}
            style={{ display: "flex", alignItems: "center" }}
            marginBottom={1}
          >
            <Typography
              variant="h6"
              style={{ color: "white" }}
              display={{ xs: "none", md: "block" }}
            >
              Wedstrijd
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Paper>
  );
};

export default WedstrijdRayon;
