import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Typography, Container } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

const Wedstrijd4 = () => {
  return (
    <Paper elevation={2} style={{ backgroundColor: "#652d90" }}>
      <Container>
        <Grid container marginTop={2}>
          <Grid md={12} marginTop={2}>
            <Typography variant="h5" style={{ color: "white" }}>
              Wedstrijd Kalender 2022-2023:
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid md={12}>
            <Typography variant="h6" style={{ color: "white" }}>
              Mogelijke opties voor Sup D
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} marginTop={1}>
          <Grid md={9} style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6" style={{ color: "white" }}>
              Zaterdag 26 november 2022
            </Typography>
          </Grid>
          <Grid md={3} style={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="h6"
              style={{ color: "white" }}
              display={{ xs: "none", md: "block" }}
            >
              PW 1
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid md={9} style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6" style={{ color: "white" }}>
              Za 10 & zo 11 december 2022
            </Typography>
          </Grid>
          <Grid md={3} style={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="h6"
              style={{ color: "white" }}
              display={{ xs: "none", md: "block" }}
            >
              PW 1
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid md={9} style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6" style={{ color: "white" }}>
              Zaterdag 14 januari 2023
            </Typography>
          </Grid>
          <Grid md={3} style={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="h6"
              style={{ color: "white" }}
              display={{ xs: "none", md: "block" }}
            >
              PW 2
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid md={9} style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6" style={{ color: "white" }}>
              Za 21 & zo 22 januari 2023
            </Typography>
          </Grid>
          <Grid md={3} style={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="h6"
              style={{ color: "white" }}
              display={{ xs: "none", md: "block" }}
            >
              PW 2
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid md={9} style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6" style={{ color: "white" }}>
              Za 11 & zo 12 februari 2023
            </Typography>
          </Grid>
          <Grid md={3} style={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="h6"
              style={{ color: "white" }}
              display={{ xs: "none", md: "block" }}
            >
              DK MK
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid
            md={9}
            style={{ display: "flex", alignItems: "center" }}
            marginBottom={11}
          >
            <Typography variant="h6" style={{ color: "white" }}>
              Za 13 & zo 14 mei 2023
            </Typography>
          </Grid>
          <Grid
            md={3}
            style={{ display: "flex", alignItems: "center" }}
            marginBottom={11}
          >
            <Typography
              variant="h6"
              style={{ color: "white" }}
              display={{ xs: "none", md: "block" }}
            >
              ToestelF
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Paper>
  );
};

export default Wedstrijd4;
