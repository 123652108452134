import Paper from "@mui/material/Paper";
import { Typography, Container } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

const ListWedstrijdenMobile = ({ wedstrijden }: any) => {
  return (
    <>
      {wedstrijden.map((wedstrijd: any) => (
        <Paper style={{ width: "100%", backgroundColor: "#652d90" }}>
          <Container key={wedstrijd.id}>
            <Grid container marginTop={1}>
              <Grid xs={12} marginTop={2}>
                <Paper style={{ backgroundColor: "#00bdd9", height: 35 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    {wedstrijd.wedstrijd}
                  </Typography>
                </Paper>
              </Grid>
              <Grid xs={12} marginTop={1}>
                <Paper style={{ backgroundColor: "#00bdd9", height: 35 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    {wedstrijd.datum}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid container marginTop={1}>
              <Grid xs={4}>
                <Paper style={{ backgroundColor: "#A3CC4C", height: 35 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    D S1
                  </Typography>
                </Paper>
              </Grid>
              <Grid xs={4}>
                <Paper style={{ backgroundColor: "#A3CC4C", height: 35 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    E S1
                  </Typography>
                </Paper>
              </Grid>
              <Grid xs={4}>
                <Paper style={{ backgroundColor: "#A3CC4C", height: 35 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    T S1
                  </Typography>
                </Paper>
              </Grid>
            </Grid>

            <Grid container>
              <Grid xs={4}>
                <Paper style={{ backgroundColor: "#00bdd9", height: 35 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    {wedstrijd.dSprong1}
                  </Typography>
                </Paper>
              </Grid>
              <Grid xs={4}>
                <Paper style={{ backgroundColor: "#00bdd9", height: 35 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    {wedstrijd.eSprong1}
                  </Typography>
                </Paper>
              </Grid>
              <Grid xs={4}>
                <Paper style={{ backgroundColor: "#00bdd9", height: 35 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    {wedstrijd.tSprong1}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>

            <Grid container marginTop={1}>
              <Grid xs={3}>
                <Paper style={{ backgroundColor: "#A3CC4C", height: 35 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    D S2
                  </Typography>
                </Paper>
              </Grid>
              <Grid xs={3}>
                <Paper style={{ backgroundColor: "#A3CC4C", height: 35 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    E S2
                  </Typography>
                </Paper>
              </Grid>
              <Grid xs={3}>
                <Paper style={{ backgroundColor: "#A3CC4C", height: 35 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    T S2
                  </Typography>
                </Paper>
              </Grid>
              <Grid xs={3}>
                <Paper style={{ backgroundColor: "#A3CC4C", height: 35 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    P Sprong
                  </Typography>
                </Paper>
              </Grid>
            </Grid>

            <Grid container>
              <Grid xs={3}>
                <Paper style={{ backgroundColor: "#00bdd9", height: 35 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    {wedstrijd.dSprong2}
                  </Typography>
                </Paper>
              </Grid>
              <Grid xs={3}>
                <Paper style={{ backgroundColor: "#00bdd9", height: 35 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    {wedstrijd.eSprong2}
                  </Typography>
                </Paper>
              </Grid>
              <Grid xs={3}>
                <Paper style={{ backgroundColor: "#00bdd9", height: 35 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    {wedstrijd.tSprong2}
                  </Typography>
                </Paper>
              </Grid>
              <Grid xs={3}>
                <Paper style={{ backgroundColor: "#00bdd9", height: 35 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    {wedstrijd.pSprong}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>

            <Grid container marginTop={1}>
              <Grid xs={3}>
                <Paper style={{ backgroundColor: "#A3CC4C", height: 35 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    D Brug
                  </Typography>
                </Paper>
              </Grid>
              <Grid xs={3}>
                <Paper style={{ backgroundColor: "#A3CC4C", height: 35 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    E Brug
                  </Typography>
                </Paper>
              </Grid>
              <Grid xs={3}>
                <Paper style={{ backgroundColor: "#A3CC4C", height: 35 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    T Brug
                  </Typography>
                </Paper>
              </Grid>
              <Grid xs={3}>
                <Paper style={{ backgroundColor: "#A3CC4C", height: 35 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    P Brug
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid container>
              <Grid xs={3}>
                <Paper style={{ backgroundColor: "#00bdd9", height: 35 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    {wedstrijd.dBrug}
                  </Typography>
                </Paper>
              </Grid>
              <Grid xs={3}>
                <Paper style={{ backgroundColor: "#00bdd9", height: 35 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    {wedstrijd.eBrug}
                  </Typography>
                </Paper>
              </Grid>
              <Grid xs={3}>
                <Paper style={{ backgroundColor: "#00bdd9", height: 35 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    {wedstrijd.tBrug}
                  </Typography>
                </Paper>
              </Grid>
              <Grid xs={3}>
                <Paper style={{ backgroundColor: "#00bdd9", height: 35 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    {wedstrijd.pBrug}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>

            <Grid container marginTop={1}>
              <Grid xs={3}>
                <Paper style={{ backgroundColor: "#A3CC4C", height: 35 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    D Balk
                  </Typography>
                </Paper>
              </Grid>
              <Grid xs={3}>
                <Paper style={{ backgroundColor: "#A3CC4C", height: 35 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    E Balk
                  </Typography>
                </Paper>
              </Grid>
              <Grid xs={3}>
                <Paper style={{ backgroundColor: "#A3CC4C", height: 35 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    T Balk
                  </Typography>
                </Paper>
              </Grid>
              <Grid xs={3}>
                <Paper style={{ backgroundColor: "#A3CC4C", height: 35 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    P Balk
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid container>
              <Grid xs={3}>
                <Paper style={{ backgroundColor: "#00bdd9", height: 35 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    {wedstrijd.dBalk}
                  </Typography>
                </Paper>
              </Grid>
              <Grid xs={3}>
                <Paper style={{ backgroundColor: "#00bdd9", height: 35 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    {wedstrijd.eBalk}
                  </Typography>
                </Paper>
              </Grid>
              <Grid xs={3}>
                <Paper style={{ backgroundColor: "#00bdd9", height: 35 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    {wedstrijd.tBalk}
                  </Typography>
                </Paper>
              </Grid>
              <Grid xs={3}>
                <Paper style={{ backgroundColor: "#00bdd9", height: 35 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    {wedstrijd.pBalk}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>

            <Grid container marginTop={1}>
              <Grid xs={3}>
                <Paper style={{ backgroundColor: "#A3CC4C", height: 35 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    D Vloer
                  </Typography>
                </Paper>
              </Grid>
              <Grid xs={3}>
                <Paper style={{ backgroundColor: "#A3CC4C", height: 35 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    E Vloer
                  </Typography>
                </Paper>
              </Grid>
              <Grid xs={3}>
                <Paper style={{ backgroundColor: "#A3CC4C", height: 35 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    T Vloer
                  </Typography>
                </Paper>
              </Grid>
              <Grid xs={3}>
                <Paper style={{ backgroundColor: "#A3CC4C", height: 35 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    P Vloer
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid container>
              <Grid xs={3}>
                <Paper style={{ backgroundColor: "#00bdd9", height: 35 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    {wedstrijd.dVloer}
                  </Typography>
                </Paper>
              </Grid>
              <Grid xs={3}>
                <Paper style={{ backgroundColor: "#00bdd9", height: 35 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    {wedstrijd.eVloer}
                  </Typography>
                </Paper>
              </Grid>
              <Grid xs={3}>
                <Paper style={{ backgroundColor: "#00bdd9", height: 35 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    {wedstrijd.tVloer}
                  </Typography>
                </Paper>
              </Grid>
              <Grid xs={3}>
                <Paper style={{ backgroundColor: "#00bdd9", height: 35 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    {wedstrijd.pVloer}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>

            <Grid container marginTop={1}>
              <Grid xs={12}>
                <Paper style={{ backgroundColor: "#A3CC4C", height: 35 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    Evaluatie
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid container paddingBottom={2}>
              <Grid xs={12}>
                <Paper style={{ backgroundColor: "#00bdd9", height: 35 }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    {wedstrijd.notes}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Paper>
      ))}
    </>
  );
};

export default ListWedstrijdenMobile;
