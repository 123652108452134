import { createContext, useEffect, useReducer, useState } from "react";
import React, { ReactNode } from "react";
import { auth } from "../firebase/config";
import { onAuthStateChanged } from "firebase/auth";

export type Dispatch = (action: any) => void;
type User = {
  email: string;
  password: string;
  displayName: string;
  uid: string;
  username: string;
};

export const AuthContext = createContext<
  { dispatch: Dispatch; user: User } | undefined
>(undefined);

interface IProps {
  children: React.ReactNode;
}

export const authReducer = (state: any, action: any) => {
  switch (action.type) {
    case "LOGIN":
      return { ...state, user: action.payload };
    case "LOGOUT":
      return { ...state, user: null };
    case "AUTH_IS_READY":
      return { ...state, user: action.payload, authIsReady: true };
    default:
      return state;
  }
};

export const AuthContextProvider: React.FunctionComponent<IProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(authReducer, {
    user: null,
    authIsReacdy: false,
  });

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, (user) => {
      dispatch({ type: "AUTH_IS_READY", payload: user });
      unsub();
    });
  }, []);

  return (
    <AuthContext.Provider value={{ ...state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
