import React, { useState } from "react";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  Grid,
  Typography,
  Container,
  TextField,
  Paper,
  Box,
} from "@mui/material";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import AddIcon from "@mui/icons-material/Add";
import {
  collection,
  query,
  updateDoc,
  where,
  setDoc,
  doc,
} from "@firebase/firestore";
import { db } from "../../../firebase/config";
import CancelIcon from "@mui/icons-material/Cancel";

export const ModalSkillMobile = ({ path }: any) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [skill, setSkill] = useState<string>("");
  const [w1, setW1] = useState<string>("");
  const [w2, setW2] = useState<string>("");
  const [w3, setW3] = useState<string>("");
  const [w4, setW4] = useState<string>("");
  const [w5, setW5] = useState<string>("");
  const [w6, setW6] = useState<string>("");
  const [w7, setW7] = useState<string>("");
  const [w8, setW8] = useState<string>("");

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    height: 300,
    bgcolor: "background.paper",
    border: "2px solid #652d90",
    boxShadow: 24,
    borderRadius: "5px",
    p: 4,
  };

  const handleUpdate = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await updateDoc(doc(db, path), {
        skill: skill,
        w1: w1,
        w2: w2,
        w3: w3,
        w4: w4,
        w5: w5,
        w6: w6,
        w7: w7,
        w8: w8,
      });
      setSkill("");
      setW1("");
      setW2("");
      setW3("");
      setW4("");
      setW5("");
      setW6("");
      setW7("");
      setW8("");
      handleClose();
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleOpen}>
        <EditOutlinedIcon />
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <form onSubmit={handleUpdate}>
            <Paper style={{ width: "100%" }}>
              <Container>
                <Grid container spacing={1} marginTop={1} marginBottom={4}>
                  <Grid item md={3} xs={12} marginBottom={2}>
                    <TextField
                      label="Voeg skill toe"
                      color="secondary"
                      variant="outlined"
                      fullWidth
                      value={skill}
                      onChange={(event) => setSkill(event.target.value)}
                    />
                  </Grid>
                  <Grid item md={1} xs={3} marginBottom={2}>
                    <TextField
                      label="W1"
                      color="secondary"
                      variant="outlined"
                      fullWidth
                      value={w1}
                      sx={{ marginLeft: "5px" }}
                      onChange={(event) => setW1(event.target.value)}
                    />
                  </Grid>
                  <Grid item md={1} xs={3} marginBottom={2}>
                    <TextField
                      label="W2"
                      color="secondary"
                      variant="outlined"
                      fullWidth
                      value={w2}
                      sx={{ marginLeft: "5px" }}
                      onChange={(event) => setW2(event.target.value)}
                    />
                  </Grid>
                  <Grid item md={1} xs={3} marginBottom={2}>
                    <TextField
                      label="W3"
                      color="secondary"
                      variant="outlined"
                      fullWidth
                      value={w3}
                      sx={{ marginLeft: "5px" }}
                      onChange={(event) => setW3(event.target.value)}
                    />
                  </Grid>
                  <Grid item md={1} xs={3} marginBottom={2}>
                    <TextField
                      label="W4"
                      color="secondary"
                      variant="outlined"
                      fullWidth
                      value={w4}
                      sx={{ marginLeft: "5px" }}
                      onChange={(event) => setW4(event.target.value)}
                    />
                  </Grid>
                  <Grid item md={1} xs={3} marginBottom={2}>
                    <TextField
                      label="W5"
                      color="secondary"
                      variant="outlined"
                      fullWidth
                      value={w5}
                      sx={{ marginLeft: "5px" }}
                      onChange={(event) => setW5(event.target.value)}
                    />
                  </Grid>
                  <Grid item md={1} xs={3} marginBottom={2}>
                    <TextField
                      label="W6"
                      color="secondary"
                      variant="outlined"
                      fullWidth
                      value={w6}
                      sx={{ marginLeft: "5px" }}
                      onChange={(event) => setW6(event.target.value)}
                    />
                  </Grid>
                  <Grid item md={1} xs={3} marginBottom={2}>
                    <TextField
                      label="W7"
                      color="secondary"
                      variant="outlined"
                      fullWidth
                      value={w7}
                      sx={{ marginLeft: "5px" }}
                      onChange={(event) => setW7(event.target.value)}
                    />
                  </Grid>
                  <Grid item md={1} xs={3} marginBottom={2}>
                    <TextField
                      label="W8"
                      color="secondary"
                      variant="outlined"
                      fullWidth
                      value={w8}
                      sx={{ marginLeft: "5px" }}
                      onChange={(event) => setW8(event.target.value)}
                    />
                  </Grid>
                  <Grid item md={1} xs={6}>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      type="submit"
                      sx={{ width: 75, height: 55, marginLeft: "20px" }}
                    >
                      <AddIcon />
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      onClick={handleClose}
                      sx={{ width: 75, height: 55, marginLeft: "20px" }}
                    >
                      <CancelIcon />
                    </Button>
                  </Grid>
                </Grid>
              </Container>
            </Paper>
          </form>
        </Box>
      </Modal>
    </>
  );
};
