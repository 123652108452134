import { Grid, Typography, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAuthContext } from "../../hooks/useAuthContext";
import AddGoal from "./components/AddGoal";
import {
  collection,
  query,
  onSnapshot,
  where,
  orderBy,
  doc,
} from "firebase/firestore";
import { db } from "../../firebase/config";
import ListGoal from "./components/ListGoal";

const GoalsPage = () => {
  const { user } = useAuthContext();
  const [goalList, setGoalList] = useState([]);

  useEffect(() => {
    const ref = query(
      collection(db, "4WeekGoals", user.uid, "userGoals"),
      orderBy("createdAt", "desc")
    );

    const unsub = onSnapshot(ref, (snapshot) => {
      let results: any = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setGoalList(results);
    });
    return () => unsub();
  }, []);

  return (
    <div>
      <Grid container justifyContent="center" marginTop={4}>
        <Typography
          variant="h4"
          style={{ color: "#EB008B", marginTop: "20px" }}
        >
          4 Week Goals
        </Typography>
      </Grid>
      <Grid container justifyContent="center" marginTop={4}>
        <AddGoal uid={user.uid} />
      </Grid>
      <Grid container justifyContent="center" marginTop={4}>
        {goalList && <ListGoal goals={goalList} />}
      </Grid>
    </div>
  );
};

export default GoalsPage;
