import { Typography, Grid, Container } from "@mui/material";
import TeamInfo from "./components/TeamInfo";
import Wedstrijd from "./components/Wedstrijd";

import Wedstrijd4 from "./components/Wedstrijd4";
import WedstrijdRayon from "./components/WedstrijdRayon";

const TeamPage = () => {
  return (
    <div>
      <Container>
        <Typography
          variant="h3"
          style={{ color: "#EB008B", marginTop: "20px" }}
        >
          Levitas - Turnen Dames Bovenbouw
        </Typography>
        <Grid container spacing={2}>
          <Grid item md={6} marginTop={4}>
            <TeamInfo />
            <Wedstrijd4 />
          </Grid>
          <Grid item md={6} marginTop={4}>
            <Wedstrijd />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <WedstrijdRayon />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default TeamPage;
