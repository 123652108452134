import React from "react";
import { Typography, Container, Paper, Grid, Button } from "@mui/material";
import { useAuthContext } from "../../../hooks/useAuthContext";
import {
  doc,
  updateDoc,
  deleteDoc,
  collection,
  getDocs,
} from "firebase/firestore";
import { on } from "events";
import { db } from "../../../firebase/config";
import Checkbox from "@mui/material/Checkbox";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

export const ListDScore = ({ dScores }: any) => {
  const { user } = useAuthContext();

  const toggleComplete1 = async (dScore: any) => {
    await updateDoc(doc(db, "DScore", user.uid, "userDScore", dScore.id), {
      s1: !dScore.s1,
    });
  };

  const toggleComplete2 = async (dScore: any) => {
    await updateDoc(doc(db, "DScore", user.uid, "userDScore", dScore.id), {
      s2: !dScore.s2,
    });
  };

  const toggleComplete3 = async (dScore: any) => {
    await updateDoc(doc(db, "DScore", user.uid, "userDScore", dScore.id), {
      s3: !dScore.s3,
    });
  };

  const toggleComplete4 = async (dScore: any) => {
    await updateDoc(doc(db, "DScore", user.uid, "userDScore", dScore.id), {
      s4: !dScore.s4,
    });
  };

  const handleDelete = async (id: any) => {
    const ref = doc(db, "DScore", user.uid, "userDScore", id);
    await deleteDoc(ref);
  };

  return (
    <>
      {dScores.map((dScore: any) => (
        <Grid container marginTop={2}>
          <Paper
            style={{ width: "90%" }}
            sx={{ border: 2, borderColor: "#652d90" }}
          >
            <Container key={dScore.id}>
              <Grid container paddingTop={1}>
                <Grid item md={3}>
                  <Paper style={{ backgroundColor: "#652d90", height: 35 }}>
                    <Typography variant="h6" style={{ color: "white" }}>
                      Datum:
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item md={3}>
                  <Paper style={{ height: 35 }}>
                    <Typography variant="h6" style={{ color: "#652d90" }}>
                      {dScore.date}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item md={3}>
                  <Paper style={{ backgroundColor: "#652d90", height: 35 }}>
                    <Typography variant="h6" style={{ color: "white" }}>
                      Toestel:
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item md={3}>
                  <Paper style={{ height: 35 }}>
                    <Typography variant="h6" style={{ color: "#652d90" }}>
                      {dScore.apparatus}
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
              <Grid container marginTop={1}>
                <Grid item md={12}>
                  <Typography
                    variant="h6"
                    style={{ color: "#652d90", whiteSpace: "pre-line" }}
                  >
                    {dScore.routine}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container alignItems="center" spacing={2} marginTop={1}>
                <Grid item md={1}>
                  <Paper style={{ backgroundColor: "#652d90", height: 35 }}>
                    <Typography variant="h6" style={{ color: "white" }}>
                      SE
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item md={1}>
                  <Checkbox
                    checked={dScore.s1}
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                    onClick={() => toggleComplete1(dScore)}
                  />
                </Grid>
                <Grid item md={1}>
                  <Checkbox
                    checked={dScore.s2}
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                    onClick={() => toggleComplete2(dScore)}
                  />
                </Grid>
                <Grid item md={1}>
                  <Checkbox
                    checked={dScore.s3}
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                    onClick={() => toggleComplete3(dScore)}
                  />
                </Grid>
                <Grid item md={1}>
                  <Checkbox
                    checked={dScore.s4}
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                    onClick={() => toggleComplete4(dScore)}
                  />
                </Grid>
                <Grid item md={1}>
                  <Paper style={{ backgroundColor: "#652d90", height: 35 }}>
                    <Typography variant="h6" style={{ color: "white" }}>
                      MW
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item md={1}>
                  <Paper style={{ backgroundColor: "#652d90", height: 35 }}>
                    <Typography variant="h6" style={{ color: "white" }}>
                      TA
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item md={1}>
                  <Paper style={{ backgroundColor: "#652d90", height: 35 }}>
                    <Typography variant="h6" style={{ color: "white" }}>
                      A
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item md={1}>
                  <Paper style={{ backgroundColor: "#652d90", height: 35 }}>
                    <Typography variant="h6" style={{ color: "white" }}>
                      B
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item md={1}>
                  <Paper style={{ backgroundColor: "#652d90", height: 35 }}>
                    <Typography variant="h6" style={{ color: "white" }}>
                      C
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item md={1}>
                  <Paper style={{ backgroundColor: "#652d90", height: 35 }}>
                    <Typography variant="h6" style={{ color: "white" }}>
                      D
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item md={1}>
                  <Paper style={{ backgroundColor: "#652d90", height: 35 }}>
                    <Typography variant="h6" style={{ color: "white" }}>
                      E
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
              <Grid container alignItems="center" spacing={2} marginTop={1}>
                <Grid item md={1}>
                  <Paper style={{ backgroundColor: "#652d90", height: 35 }}>
                    <Typography variant="h6" style={{ color: "white" }}>
                      MW
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item md={1}>
                  <Paper style={{ height: 35 }}>
                    <Typography variant="h6" style={{ color: "#652d90" }}>
                      {dScore.mw}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item md={1}>
                  <Paper style={{ backgroundColor: "#652d90", height: 35 }}>
                    <Typography variant="h6" style={{ color: "white" }}>
                      SE
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item md={1}>
                  <Paper style={{ height: 35 }}>
                    <Typography variant="h6" style={{ color: "#652d90" }}>
                      {dScore.se}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item md={1}></Grid>
                <Grid item md={1}>
                  <Paper style={{ backgroundColor: "#652d90", height: 35 }}>
                    <Typography variant="h6" style={{ color: "white" }}>
                      Acro
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item md={1}>
                  <Paper style={{ height: 35 }}>
                    <Typography variant="h6" style={{ color: "#652d90" }}>
                      {dScore.acroTA}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item md={1}>
                  <Paper style={{ height: 35 }}>
                    <Typography variant="h6" style={{ color: "#652d90" }}>
                      {dScore.acroA}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item md={1}>
                  <Paper style={{ height: 35 }}>
                    <Typography variant="h6" style={{ color: "#652d90" }}>
                      {dScore.acroB}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item md={1}>
                  <Paper style={{ height: 35 }}>
                    <Typography variant="h6" style={{ color: "#652d90" }}>
                      {dScore.acroC}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item md={1}>
                  <Paper style={{ height: 35 }}>
                    <Typography variant="h6" style={{ color: "#652d90" }}>
                      {dScore.acroD}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item md={1}>
                  <Paper style={{ height: 35 }}>
                    <Typography variant="h6" style={{ color: "#652d90" }}>
                      {dScore.acroE}
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
              <Grid container alignItems="center" spacing={2} marginTop={1}>
                <Grid item md={1}>
                  <Paper style={{ backgroundColor: "#652d90", height: 35 }}>
                    <Typography variant="h6" style={{ color: "white" }}>
                      VW
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item md={1}>
                  <Paper style={{ height: 35 }}>
                    <Typography variant="h6" style={{ color: "#652d90" }}>
                      {dScore.vw}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item md={1}>
                  <Paper style={{ backgroundColor: "#652d90", height: 35 }}>
                    <Typography variant="h6" style={{ color: "white" }}>
                      D score
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item md={1}>
                  <Paper style={{ height: 35 }}>
                    <Typography variant="h6" style={{ color: "#652d90" }}>
                      {dScore.dScore}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item md={1}></Grid>
                <Grid item md={1}>
                  <Paper style={{ backgroundColor: "#652d90", height: 35 }}>
                    <Typography variant="h6" style={{ color: "white" }}>
                      Dans
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item md={1}>
                  <Paper style={{ height: 35 }}>
                    <Typography variant="h6" style={{ color: "#652d90" }}>
                      {dScore.dansTA}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item md={1}>
                  <Paper style={{ height: 35 }}>
                    <Typography variant="h6" style={{ color: "#652d90" }}>
                      {dScore.dansA}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item md={1}>
                  <Paper style={{ height: 35 }}>
                    <Typography variant="h6" style={{ color: "#652d90" }}>
                      {dScore.dansB}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item md={1}>
                  <Paper style={{ height: 35 }}>
                    <Typography variant="h6" style={{ color: "#652d90" }}>
                      {dScore.dansC}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item md={1}>
                  <Paper style={{ height: 35 }}>
                    <Typography variant="h6" style={{ color: "#652d90" }}>
                      {dScore.dansD}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item md={1}>
                  <Paper style={{ height: 35 }}>
                    <Typography variant="h6" style={{ color: "#652d90" }}>
                      {dScore.dansE}
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                spacing={2}
                marginTop={1}
                paddingBottom={1}
              >
                <Grid item md={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleDelete(dScore.id)}
                  >
                    <DeleteOutlinedIcon />
                  </Button>
                </Grid>
                <Grid item md={1}></Grid>
                <Grid item md={1}>
                  <Paper style={{ backgroundColor: "#652d90", height: 35 }}>
                    <Typography variant="h6" style={{ color: "white" }}>
                      N aftrek
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item md={1}>
                  <Paper style={{ height: 35 }}>
                    <Typography variant="h6" style={{ color: "#652d90" }}>
                      {dScore.nAftrek}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item md={1}></Grid>
                <Grid item md={1}>
                  <Paper style={{ backgroundColor: "#652d90", height: 35 }}>
                    <Typography variant="h6" style={{ color: "white" }}>
                      Over
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item md={1}>
                  <Paper style={{ height: 35 }}>
                    <Typography variant="h6" style={{ color: "#652d90" }}>
                      {dScore.overTA}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item md={1}>
                  <Paper style={{ height: 35 }}>
                    <Typography variant="h6" style={{ color: "#652d90" }}>
                      {dScore.overA}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item md={1}>
                  <Paper style={{ height: 35 }}>
                    <Typography variant="h6" style={{ color: "#652d90" }}>
                      {dScore.overB}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item md={1}>
                  <Paper style={{ height: 35 }}>
                    <Typography variant="h6" style={{ color: "#652d90" }}>
                      {dScore.overC}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item md={1}>
                  <Paper style={{ height: 35 }}>
                    <Typography variant="h6" style={{ color: "#652d90" }}>
                      {dScore.overD}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item md={1}>
                  <Paper style={{ height: 35 }}>
                    <Typography variant="h6" style={{ color: "#652d90" }}>
                      {dScore.overE}
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
            </Container>
          </Paper>
        </Grid>
      ))}
    </>
  );
};
