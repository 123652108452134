import { Typography, Container } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Paper from "@mui/material/Paper";
import SupA from "./components/SupA";
import SupB from "./components/SupB";
import SupC from "./components/SupC";
import SupD from "./components/SupD";
import SupE from "./components/SupE";
import SupF from "./components/SupF";
import SupG from "./components/SupG";

const RegelementenPage = () => {
  return (
    <div>
      <Container>
        <Typography
          variant="h3"
          style={{ color: "#EB008B", marginTop: "20px" }}
        >
          Supplementen - Keuze Oefenstof
        </Typography>
        <SupA />
        <SupB />
        <SupC />
        <SupD />
        <SupE />
        <SupF />
        <SupG />
      </Container>
    </div>
  );
};

export default RegelementenPage;
