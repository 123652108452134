import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import Button from "@mui/material/Button";
import { Grid, Container } from "@mui/material";
import { useLogout } from "../hooks/useLogout";
import { useAuthContext } from "../hooks/useAuthContext";
import { useEffect, useState } from "react";
import { onSnapshot, collection, query, where } from "@firebase/firestore";
import { db } from "../firebase/config";
import HomeIcon from "@mui/icons-material/Home";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ImportContactsOutlinedIcon from "@mui/icons-material/ImportContactsOutlined";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { Navigate } from "react-router-dom";
import { UserList } from "./UserList";

const Navbar = () => {
  const { logout } = useLogout();
  const { user } = useAuthContext();

  return (
    <AppBar position="sticky" style={{ height: 75 }}>
      <Container>
        <Grid alignItems="center">
          <Toolbar>
            <Grid item marginRight={1}>
              <NavLink to="/">
                <HomeIcon color="error" />
              </NavLink>
            </Grid>
            {user && (
              <>
                {/* <Navigate replace to="/team" /> */}
                <Grid item marginRight={1}>
                  <NavLink to="/team">
                    <PeopleOutlinedIcon color="error" />
                  </NavLink>
                </Grid>
                <Grid item marginRight={1}>
                  <NavLink to="/regelementen">
                    <ImportContactsOutlinedIcon color="error" />
                  </NavLink>
                </Grid>
                <Grid item marginRight={1}>
                  <NavLink to="/goals">
                    <FlagOutlinedIcon color="error" />
                  </NavLink>
                </Grid>
                <Grid item marginRight={1}>
                  <NavLink to="/skills">
                    <TimelineOutlinedIcon color="error" />
                  </NavLink>
                </Grid>
                <Grid item marginRight={1}>
                  <NavLink to="/wedstrijden">
                    <EmojiEventsOutlinedIcon color="error" />
                  </NavLink>
                </Grid>
                <Grid item marginRight={1}>
                  <NavLink to="/dscore">
                    <DescriptionOutlinedIcon color="error" />
                  </NavLink>
                </Grid>

                <UserList path={`users`} />

                <Grid item>
                  <LogoutOutlinedIcon color="error" onClick={logout} />
                </Grid>
              </>
            )}
            {!user && (
              <>
                {/* <Navigate replace to="/signup" /> */}
                <Grid marginLeft="auto">
                  <NavLink className="nav-link" to="/login">
                    Login
                  </NavLink>
                </Grid>
                <Grid>
                  <NavLink className="nav-link" to="/signup">
                    Signup
                  </NavLink>
                </Grid>
              </>
            )}
          </Toolbar>
        </Grid>
      </Container>
    </AppBar>
  );
};

export default Navbar;
